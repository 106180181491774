<template>
  <photoclip @clip="uploadImage" />
</template>

<script>
import { Toast } from 'vant'

function dataURLtoFile(dataURL, filename = 'photoclip') {
  let arr = dataURL.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}

export default {
  methods: {
    async uploadImage({ dataURL }) {
      Toast.loading({
        message: '请稍候...',
        forbidClick: true
      })
      let uploadSuccess = false
      try {
        const data = new FormData()
        data.append('file', dataURLtoFile(dataURL))
        const res = await this.$axios.post(
          `/video/v1/custom_adv/uploadMyPic`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (res.errCode === 0) {
          if (this.$root._cache.oncePhotoclipSuccess) {
            this.$root._cache.oncePhotoclipSuccess(res.data.image)
          }
          uploadSuccess = true
          Toast.clear()
          this.$router.go(-1)
        } else {
          Toast(res.errMsg)
        }
      } catch (error) {
        console.error(error)
        Toast('上传图片失败')
      }
      setTimeout(() => {
        if (!uploadSuccess) {
          Toast('上传图片超时')
        }
      }, 10000)
    }
  }
}
</script>
