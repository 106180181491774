import axios from "axios";
import { OLD_API_BASE_URL, haibaoServApiBaseURL, ENV } from "@/env.js";
import { storageKeys } from "@/env";

// 创建axios实例
export const service = axios.create({
  baseURL: OLD_API_BASE_URL, // api 的 base_url
  timeout: 10000, // 请求超时时间
  transformRequest: [
    function (data) {
      let ret = "";
      for (const it in data) {
        ret +=
          encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
      }
      return ret;
    },
  ],
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 105) {
      // 需要重新授权
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.officialopenid = localStorage.getItem(
      storageKeys.official_open_id
    );
    config.headers["token"] = localStorage[storageKeys.token]; // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers["AUTHORIZATION"] = localStorage[storageKeys.token]; // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  }
);

const haibaoBaseApiSubURLs = {
  dev: "/base-api",
  prod: "/baseapi",
};
const haibaoBaseApiSubURL = haibaoBaseApiSubURLs[ENV];

export const api_send_verify_code = (data) => {
  return service.get(
    `${haibaoServApiBaseURL}${haibaoBaseApiSubURL}/v1/sms/send_verify_code`,
    { params: data }
  );
};

export const api_verify_code_bind = (data) => {
  return service.post(
    `${haibaoServApiBaseURL}${haibaoBaseApiSubURL}/v1/user/verify_code_bind`,
    data
  );
};

export const vip_status = (data) => {
  return service.post(
    `${haibaoServApiBaseURL}${haibaoBaseApiSubURL}/v1/user/vip_status`,
    data
  );
};
