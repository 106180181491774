<template>
  <button class="v-button" @click="onClick"><slot></slot></button>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
button.v-button {
  height: 88px;
  line-height: 88px;
  font-size: 28px;
  text-align: center;
  color: #8D590D;
  background: #00b7ae;
  background: linear-gradient(136deg, #FBE29D 0%, #F4C265 100%);
  box-shadow: 0px 4px 8px 0px rgba(244, 194, 101, 0.42);
  padding: 0 96px;
  border-radius: 44px;
  border: none;
  &::after {
    border: none;
  }
}
</style>
