export const ENV =
  process.env.VUE_APP_DEPLOYMENT_ENV === 'production' ? 'prod' : 'prod'

const servBaseURLs = {
  dev: 'http://vpc-test.topmdrt.com/video',
  prod: 'https://video-api.bxrtoutiao.com'
}

export const servBaseURL = servBaseURLs[ENV]

const OLD_API_BASE_URLS = {
  dev: 'https://vpc-test.topmdrt.com/daka/',
  prod: 'https://daka-news.topmdrt.com/'
}
export const OLD_API_BASE_URL = OLD_API_BASE_URLS[ENV]

const haibaoServApiBaseURLs = {
  dev: 'https://vpc-test.topmdrt.com/haibao',
  prod: 'https://haibao.topmdrt.com'
}
export const haibaoServApiBaseURL = haibaoServApiBaseURLs[ENV]

const videoPublicURLs = {
  dev: 'http://act-test.topmdrt.com/video-client/dist/#/',
  prod: 'https://video.supermdrt.info/#/'
}

export const videoPublicURL = videoPublicURLs[ENV]

export const tokenAuthURL = servBaseURLs[ENV] + '/video/v1/auth/get_auth_url'

const whovmURLs = {
  dev: 'http://act-test.topmdrt.com/whoViewMe/dist',
  prod: 'https://agent.topmdrt.com'
}

export const whovmURL = whovmURLs[ENV]

const bdttURLs = {
  dev: 'http://act-test.topmdrt.com/bdtt/dist',
  prod: 'https://bdtt.vipbxr.cn/bdttn'
}

export const bdttURL = bdttURLs[ENV]

const aiSecretaryURLs = {
  dev: 'http://act-test.topmdrt.com/ai-secretary-client/dist',
  prod: 'https://ai-secretary.topmdrt.com'
}

export const aiSecretaryURL = aiSecretaryURLs[ENV]

const PUBLIC_ORIGINS = {
  dev: 'http://act-test.topmdrt.com',
  prod: 'https://weixin.video1.supermdrt.top'
}

export const PUBLIC_ORIGIN = PUBLIC_ORIGINS[ENV]

const PUBLIC_URLS = {
  dev: PUBLIC_ORIGIN + '/video-client/dist',
  prod: PUBLIC_ORIGIN
}

export const PUBLIC_URL = PUBLIC_URLS[ENV]

const PURCHASE_PUBLIC_URLS = {
  dev: PUBLIC_ORIGIN + '/video-client/dist',
  prod: 'https://videopay.supermdrt.top'
}

export const PURCHASE_PUBLIC_URL = PURCHASE_PUBLIC_URLS[ENV]

// OSS域名
export const staticURL = 'https://oss-img.topmdrt.com/video-client'

// storageKeys
export const VER = '1.0.0'
export function getStorageKey(key) {
  return `${key}_video_${VER}_${ENV}`
}
export const storageKeys = {
  token: getStorageKey('token'),
  official_open_id: getStorageKey('official_open_id'),
  source: getStorageKey('source'),
  weixinUserId: getStorageKey('weixinUserId'),
  openid: getStorageKey('openid'),
  uuid: getStorageKey('uuid'),
  entrance: getStorageKey('entrance'),
  hadShowSquareGuide: getStorageKey('had_show_square_guide'),
  myVideosNeedRefresh: getStorageKey('my_videos_need_refresh')
}

export const tcAppID = '1300517829'
