import { storageKeys } from '@/env'
/**
 * 使用Promise的方式下载Img，返回Image对象
 * @param {String} url
 */
export const promisifyLoadImg = url => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.addEventListener(
      'load',
      () => {
        resolve(img)
      },
      false
    )
    img.addEventListener(
      'error',
      () => {
        reject('加载图片' + url + '失败')
      },
      false
    )
    img.src = url
  })
}

/**
 * 将Object参数转化为FormData
 * @param {Object} data
 */
export const parse2FormData = data => {
  const bodyFormData = new FormData()
  Object.keys(data).forEach(key => {
    bodyFormData.append(key, data[key])
  })
  return bodyFormData
}

/**
 * 将Base64的图片数据转化为File
 * @param {String} urlData
 */
export const base64ToBlob = urlData => {
  var arr = urlData.split(',')
  var mime = arr[0].match(/:(.*?);/)[1] || 'image/png'
  // 去掉url的头，并转化为byte
  var bytes = window.atob(arr[1])
  // 处理异常,将ascii码小于0的转换为大于0
  var ab = new ArrayBuffer(bytes.length)
  // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  var ia = new Uint8Array(ab)

  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }

  return new Blob([ab], {
    type: mime
  })
}

function canvasDataURL(path, obj, callback) {
  var img = new Image()
  img.src = path
  img.onload = function() {
    var that = this
    // 默认按比例压缩
    var w = that.width

    var h = that.height

    var scale = w / h
    w = obj.width || w
    h = obj.height || w / scale
    var quality = 0.5 // 默认图片质量为0.7
    // 生成canvas
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')
    // 创建属性节点
    var anw = document.createAttribute('width')
    anw.nodeValue = w
    var anh = document.createAttribute('height')
    anh.nodeValue = h
    canvas.setAttributeNode(anw)
    canvas.setAttributeNode(anh)
    ctx.drawImage(that, 0, 0, w, h)
    // 图像质量
    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      quality = obj.quality
    }
    // quality值越小，所绘制出的图像越模糊
    var base64 = canvas.toDataURL('image/jpeg', quality)
    // 回调函数返回base64的值
    callback(base64)
  }
}

/**
 * 压缩图片
 * @param {File} file 图片文件
 * @param {Object} options 压缩选项
 * @param {Function} callback 将Base64格式的数据从回调函数传回
 */
export const photoCompress = (file, options, callback) => {
  var ready = new FileReader()
  /* 开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
  ready.readAsDataURL(file)
  ready.onload = function() {
    var re = this.result
    canvasDataURL(re, options, callback)
  }
}

/**
 * 压缩图片 返回Promise
 * @param {File} file 图片文件
 * @param {Number} width 目标宽度，非必填
 */
export const promisifyPhotoCompress = (file, options = {}) => {
  // 上传限制大小为2097152
  if (file.size > 4000000) {
    options.quality = parseFloat((2000000 / file.size).toFixed(4) - 0.05)
  }
  return new Promise(resolve => {
    photoCompress(file, options, data => {
      resolve(data)
    })
  })
}

/**
 * 格式化日期输出
 * @param {Date} date
 * @param {String} fmt
 */
export const formatDate = (date, fmt) => {
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }

  return fmt
}

export function getWindowScrollHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
}

/**
 * 类似微博的时间显示
 * @param {Number} timestamp
 */
export function timestampFormat(timestamp) {
  function zeroize(num) {
    return (String(num).length === 1 ? '0' : '') + num
  }

  var curTimestamp = parseInt(new Date().getTime() / 1000) // 当前时间戳
  var timestampDiff = curTimestamp - timestamp // 参数时间戳与当前时间戳相差秒数

  var curDate = new Date(curTimestamp * 1000) // 当前时间日期对象
  var tmDate = new Date(timestamp * 1000) // 参数时间戳转换成的日期对象

  var Y = tmDate.getFullYear()
  var m = tmDate.getMonth() + 1
  var d = tmDate.getDate()
  var H = tmDate.getHours()
  var i = tmDate.getMinutes()
  // var s = tmDate.getSeconds()

  if (timestampDiff < 60) {
    // 一分钟以内
    return '刚刚'
  } else if (timestampDiff < 3600) {
    // 一小时前之内
    return Math.floor(timestampDiff / 60) + '分钟前'
  } else if (
    curDate.getFullYear() === Y &&
    curDate.getMonth() + 1 === m &&
    curDate.getDate() === d
  ) {
    return zeroize(H) + ':' + zeroize(i)
  } else {
    var newDate = new Date((curTimestamp - 86400) * 1000) // 参数中的时间戳加一天转换成的日期对象
    if (
      newDate.getFullYear() === Y &&
      newDate.getMonth() + 1 === m &&
      newDate.getDate() === d
    ) {
      return '昨天 ' + zeroize(H) + ':' + zeroize(i)
    } else if (curDate.getFullYear() === Y) {
      return m + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i)
    } else {
      return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 '
    }
  }
}

// asyncLoadJs.js
export function asyncLoadJs(url, checkFn) {
  return new Promise((resolve, reject) => {
    let hasLoaded = document.querySelector('script[src="' + url + '"]')
    if (checkFn() || hasLoaded) {
      resolve()
      return
    }

    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    document.body.appendChild(script)
    script.onload = () => {
      resolve()
    }
    script.onerror = () => {
      reject()
    }
  })
}

export function appendOfficialOpenId(originURL) {
  if (originURL.includes('official_open_id')) return originURL
  let concatSymbol = '?'
  if (originURL.includes('?')) concatSymbol = '&'
  return (
    originURL +
    concatSymbol +
    'official_open_id=' +
    localStorage.getItem(storageKeys.official_open_id)
  )
}
