<template>
  <!-- 此处is-vip=true -->
  <!-- 因为接口未返回用户是否为vip，改为插入广告时根据后端返回手动控制组件：this.$refs.refAd.showNeedVip = true -->
  <advertisement
    ref="refAd"
    v-model="showMyAdList"
    :ad-list="customAdList"
    :active="currentAdIdx"
    :is-vip="true"
    :async-get-promotion-fee-detail="loadPromotionFeeDetailFn"
    @add="toAddAd"
    @select="onSelectAd"
    @edit="toEditAd"
    @delete="deleteAd"
    @toDetail="toAdDetail"
    @purchase="toPurchaseVip"
  >
    <template v-slot:display>
      <template>
        <div v-if="currentAd" class="ad-display-wrap" @click.stop="onClickAd">
          <img class="ad-cover" :src="staticURL + '/ad-gift.png'" />
          <div class="ad-title">{{ currentAd.title }}</div>
        </div>
        <a
          v-else-if="isAuthor"
          class="ad-display-wrap add"
          sensors-data='{"$element_content":"添加广告","ad_location":"视频"}'
          @click.stop="showMyAdList = true"
        >
          <van-icon name="plus" />
          <div>添加广告</div>
        </a>
      </template>
    </template>
    <template v-slot:hint>
      <div class="ad-hint-title">广告可以插入视频中发给客户</div>
      <div class="ad-hint-desc">客户点击后跳转到产品页面</div>
    </template>
  </advertisement>
</template>

<script>
import Vue from 'vue'
import axiosInstance from '@/services/axios'
import { storageKeys, staticURL } from '@/env'
import { Dialog, Icon, Toast } from 'vant'

Vue.use(Icon)

export default {
  props: ['resourceId', 'isAuthor'],
  data() {
    return {
      staticURL,
      async loadPromotionFeeDetailFn(ad) {
        try {
          const data = await axiosInstance.get(
            '/nbdtt_service/v2/custom_adv/get_one_custom_adv',
            {
              params: { advert_id: ad.id }
            }
          )
          if (data.errCode === 0) {
            return data.data.datas.promotion_fee_detail || '暂无数据'
          }
        } catch (error) {}
      },
      currentAd: null,
      currentAdIdx: -1,
      customAdList: [],
      showMyAdList: false
    }
  },
  created() {
    this.loadCustomAd()
  },
  methods: {
    async toAdDetail(ad) {
      try {
        const data = await axiosInstance.post('/video/v1/custom_adv/authXrk', {
          adv_id: ad.id
        })
        if (data.errCode === 0) {
          setTimeout(() => {
            if (data.data.sso_id) {
              window.location.href = ad.link + '&sso_id=' + data.data.sso_id
            } else {
              window.location.href = ad.link
            }
          }, 200)
        } else if (data.errCode === 1409) {
          this.showAuthPopup = true
        } else {
          this.$toast(data.errMsg)
        }
      } catch (error) {}
    },
    async loadCustomAd() {
      const data = await this.$axios.get('/video/v1/custom_adv/get_one', {
        params: {
          resources_id: this.resourceId,
          uuid: this.$store.state.suuid || ''
        }
      })
      if (data.errCode === 0) {
        const ad = data.data.datas
        if (ad && ad.id) {
          this.currentAd = ad
        }
      }
      if (this.isAuthor) {
        this.loadCustomAdList({
          success: () => {
            if (this.currentAd) {
              this.currentAdIdx = this.customAdList.findIndex(t => {
                return t.id === this.currentAd.id
              })
            }
          }
        })
      }
    },
    toPurchaseVip() {
      window.location.href = `wxurl.topbxr.cn/3fee25?official_open_id=${localStorage.getItem(
        storageKeys.official_open_id
      )}`
      // window.location.href = `http://wxurl.topbxr.cn/3fea61?official_open_id=${localStorage.getItem(
      //   storageKeys.official_open_id
      // )}`
    },
    loadCustomAdList({ success }) {
      const loadPage = async page => {
        try {
          const data = await this.$axios.get('/video/v1/custom_adv/get_list', {
            params: {
              page,
              page_num: this.adListPagination || 20
            }
          })
          const datas = data.data.list.datas
          if (data.errCode === 0) {
            if (page === 1) {
              this.customAdList = []
            }
            this.customAdList.push(...datas.data)
            if (datas.last_page > datas.current_page) {
              loadPage(page + 1)
            } else {
              const total = datas.total
              if (total > 20) {
                this.adListPagination = total + 5
              }
              if (success) success()
            }
          }
        } catch (error) {
          console.error('获取自定义广告列表失败', error)
        }
      }
      loadPage(1)
    },
    toAddAd() {
      this.showMyAdList = false
      this.setAdEditCallback()
      this.$router.push('/ad-manage')
    },
    async onClickAd() {
      if (this.isAuthor) {
        this.showMyAdList = true
      } else if (this.currentAd.link) {
        Toast.loading({
          message: '即将跳转第三方网页\n该网址由用户提供，内容和本站无关',
          overlay: true,
          forbidClick: true,
          duration: 2000
        })
        try {
          const data = await axiosInstance.post(
            '/video/v1/custom_adv/authXrk',
            {
              adv_id: this.currentAd.id
            }
          )
          if (data.errCode === 0) {
            setTimeout(() => {
              if (data.data.sso_id) {
                window.location.href =
                  this.currentAd.link + '&sso_id=' + data.data.sso_id
              } else {
                window.location.href = this.currentAd.link
              }
            }, 200)
          } else {
            this.$toast(data.errMsg)
          }
        } catch (error) {}
        this.$sensorsTrack('ClickAd', {
          ad_id: this.currentAd.id,
          ad_name: this.currentAd.title,
          ad_location: '视频广告',
          ad_type: '用户广告',
          is_author_click: this.isAuthor
        })
      }
    },
    onSelectAd(idx) {
      if (idx !== this.currentAdIdx) {
        const ad = this.customAdList[idx]
        this.$axios
          .post('/video/v1/custom_adv/insert_one', {
            resources_id: this.resourceId,
            adv_id: ad.id
          })
          .then(data => {
            if (data.errCode === 0) {
              this.currentAdIdx = idx
              this.currentAd = ad
              this.showMyAdList = false
              Toast('插入广告成功')
              this.$sensorsTrack('AddAd', {
                ad_location: '视频广告'
              })
            } else if (data.errCode === 1404) {
              this.$refs.refAd.showNeedVip = true
            } else {
              Toast(data.errMsg)
            }
          })
      } else {
        this.showMyAdList = false
      }
    },
    toEditAd(idx) {
      const ad = this.customAdList[idx]
      this.showMyAdList = false
      this.setAdEditCallback()
      this.$router.push({
        path: '/custom-ad-edit',
        query: {
          id: ad.id
        }
      })
    },
    deleteAd(idx) {
      const ad = this.customAdList[idx]
      this.showMyAdList = false
      Dialog.confirm({
        title: '提示',
        message: '确认要删除该自定义广告吗？'
      })
        .then(() => {
          this.$axios
            .post('/video/v1/custom_adv/del_one', {
              status: 1,
              adv_id: ad.id
            })
            .then(data => {
              if (data.errCode === 0) {
                if (this.currentAdIdx === idx) {
                  this.currentAdIdx = -1
                  this.currentAd = null
                }
                this.customAdList.splice(idx, 1)
                Toast('删除成功')
              }
              this.showMyAdList = true
            })
        })
        .catch(() => {
          this.showMyAdList = true
        })
    },
    setAdEditCallback() {
      if (!this.$root._cache) this.$root._cache = {}
      this.$root._cache.onceCustomAdEditBack = () => {
        this.loadCustomAd()
        this.showMyAdList = true
        delete this.$root._cache.onceCustomAdEditBack
      }
    }
  }
}
</script>

<style lang="less">
// 自定义广告相关样式
.topmdrt-ad-edit-popup {
  background: #fff !important;
}
</style>
<style lang="less" scoped>
// 自定义广告相关样式
.ad-hint-title {
  color: #101010;
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
}
.ad-hint-desc {
  color: #666;
  font-size: 28px;
}
.ad-display-wrap {
  position: absolute;
  top: 0;
  top: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 36px 36px 0px;
  height: 64px;
  padding: 0 24px 0 12px;
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 40px;
  color: #fff;
  .ad-cover {
    width: 32px;
    height: 38px;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
  &.add {
    width: 212px;
    padding: 0;
    padding-left: 16px;
    > div {
      margin-left: 16px;
    }
  }
}
</style>
