<template>
  <div />
</template>
<script>
import { storageKeys } from '@/env.js'
export default {
  data() {
    return {}
  },
  created() {
    const authServURL = this.$route.query.authServURL
    const redirectURLParamName = this.$route.query.redirectURLParamName
    const redirectURL = this.$route.query.redirectURL
    const official_open_id = localStorage.getItem(storageKeys.official_open_id)
      ? localStorage.getItem(storageKeys.official_open_id)
      : ''
    window.location.href = `${authServURL}?${redirectURLParamName}=${redirectURL}&official_open_id=${official_open_id}`
  },
  mounted() {
    window.scroll(0, 0)
  },
  methods: {}
}
</script>
<style lang="less" scoped></style>
