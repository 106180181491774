<template>
  <div class="protocol_container">
    <div class="title">超级圆桌VIP会员服务协议</div>
    <p class="textIndent">
      欢迎使用超级圆桌VIP会员（以下简称“会员”）服务（以下简称“本服务”），为了保障您的权益，请在进行下一步操作前，详细阅读并遵守《超级圆桌VIP会员服务协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容特别是免除或限制责任的相应条款，开通会员服务即视为同意该条款内容。
    </p>
    <p class="textIndent">
      除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的任何包括但不限于购买、接受赠予等获取行为，及任何包括但不限于登录、查看等使用行为，即视为您已阅读并同意本协议的约束。
    </p>
    <p class="textIndent">
      如果您未满18周岁，请在父母或法定监护人的陪同下阅读本协议并使用本服务。
    </p>
    <p class="textIndent">
      本协议软件是指用户需要通过微信公众号访问的，由深圳超桌网络科技有限公司（以下简称“超级圆桌”或“本公司”）目前及今后所服务运营的超级圆桌微网站，包括但不限于：一个单独的微网站页面，其中、英文名称或标题以及相关的电子文档、图片文件、视频以及与微网站相关的标识以及任何其他的移动端作品，统称为超级圆桌，您在使用超级圆桌会员服务时必须完全、严格遵守本服务协议条款。
    </p>
    <p class="subtitle">第一条 &nbsp;&nbsp;&nbsp;&nbsp;相关定义</p>
    <p>
      1.1
      超级VIP会员：超级VIP会员是为超级圆桌用户打造的高级会员服务，通过提供保险资讯、音频、视频、动画、名片等会员服务，让移动展业变得更加方便，高效。
    </p>
    <p class="subtitle">
      第二条 &nbsp;&nbsp;&nbsp;&nbsp;本站相关服务条款的确认和接纳
    </p>
    <p>
      2.1
      本站所提供的超级圆桌VIP会员活动的所有行为和运作归超级圆桌所有。超级圆桌所提供的服务将按照其服务条款和操作规则严格执行。
    </p>
    <p>
      2.2
      用户点击“立即开通”按钮或者其他具有类似含义的按钮时，您的行为表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与超级圆桌达成的协议，具有法律效力。超级圆桌向会员提供的全部服务，均仅限于在超级圆桌平台使用，任何以恶意破解等非法手段将超级圆桌提供的服务内容与超级圆桌平台分离的行为，均不属于本协议中约定的超级圆桌提供的服务。由此引起的一切法律后果由行为人负责，超级圆桌将依法追究行为人的法律责任。
    </p>
    <p>
      2.3
      为了让您更好地享受会员服务，开通会员的同时将默认关注超级圆桌官方公众号，如您不再需要使用本服务，可随时取消关注，在会员权益期间内可随时通过再次关注超级圆桌旗下产品享有公众号内的会员权益服务。
    </p>
    <p>
      2.4
      在成为超级VIP会员之前，请您提前了解并同意：因账户提现涉及税务和运营成本，故提现将按照您的提现金额收取10%的服务费，由平台代收；如果您收入转为超级圆桌金币，则免手续费。涉及会员开通及账户金币充值的，按照如下规定处理：
    </p>
    <p class="textIndent item">
      2.4.1【钱包】由您的客户打赏或完成平台指定任务奖励所得，满足一定条件即可提现。
    </p>
    <p class="textIndent item">
      2.4.2【金币余额】您实际充值的金币构成您的账户余额（金币）。
    </p>
    <p class="textIndent item">
      2.4.3【充值余额有效期】充值金币有效期为自充值日起至用完为止。
    </p>
    <p class="textIndent item">
      2.4.4【金币使用规则】金币仅用于兑换官方直接运营的产品与服务，金币不可转换为人民币提现、不能进行转账交易。
    </p>
    <p class="textIndent item">
      2.4.5【退款规则】在超级圆桌平台充值的7天之内（≤7天，即充值当天为第一天），您可享受7天无条件退款服务，当您申请退款时，平台在扣除充值优惠奖励以及已经消费金额后，将剩余对应金额于7个工作日内原路退还您支付帐户，退款将按照退款金额收取10%的服务费将由您承担。
    </p>
    <p>
      2.5
      您应合理使用您享有的会员权益。如果发现或收到他人举报您有任何违反本协议的行为，超级圆桌有权依法进行独立判断，并采取技术手段予以暂停服务、屏蔽或删除相关信息。同时，超级圆桌有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部服务、追究法律责任等措施，且无需向您退还任何费用，而由此给您带来的损失由您自行承担。给超级圆桌造成损失的，超级圆桌保留追究您赔偿的权利。
    </p>
    <p class="subtitle">第三条 &nbsp;&nbsp;&nbsp;&nbsp;用户会员使用注意事项</p>
    <p>
      3.1在成为会员之前，您必须通过自己且经实名认证的微信号关注超级圆桌旗下的产品。您所填写的内容与个人资料必须真实、准确、完整、合法有效，如有变动，您应及时更新。如您提供的资料不合法、不真实、不准确、不详尽，由此引起的损失及法律责任将由您自行承担。给超级圆桌造成损失的，超级圆桌保留追究您赔偿的权利。
    </p>
    <p>
      3.2
      用户可通过在本平台系统完成注册程序并通过身份认证成为会员。如会员的账号信息（包括但不限于用户名、头像、简介、个性签名等）含有不雅、不当、非法、令人反感的词汇或图像或存在以下情形的，超级圆桌可不予注册，并保留对已注册会员进行注销的权利：
    </p>
    <p class="textIndent item">
      3.2.1
      冒用党和国家领导人、社会名人、国家机构或其他社会机构的真实姓名、名称、字号、艺名、笔名注册的。
    </p>
    <p class="textIndent item">
      3.2.2 包含不文明、不健康内容，或包含歧视、侮辱、猥亵类词语的。
    </p>
    <p class="textIndent item">
      3.2.3 注册易产生歧义、引起他人误解或其他不符合法律规定的账号的。
    </p>
    <p>
      3.3
      会员应自行负责妥善且正确地保管、使用、维护您在超级圆桌的微信账户、账户信息及账户密码，并对以该帐号进行的所有活动及事件负法律责任。对账户信息和账户密码采取必要和有效的保密措施。因自身保管、使用、维护不当造成的损失，超级圆桌对此不承担任何责任。如您发现任何非法使用会员帐号或安全漏洞的情况，请立即与超级圆桌联系。
    </p>
    <p>
      3.4
      用户同意，超级圆桌拥有通过邮件、短信、微信信息、电话等形式，向其发送相关活动信息等必要信息通知的权利。
    </p>
    <p>
      3.5
      超级圆桌VIP会员服务涉及的产品所有权以及相关软件知识产权归超级圆桌所有。超级圆桌所提供的服务将按照其服务条款和操作规则严格执行。
    </p>
    <p>
      3.6
      超级圆桌享有对会员活动的监督、提示、追究的权利，如会员的行为违反本协议条款的约定或相关法律法规，超级圆桌享有要求其改正及追究其责任等权利。
    </p>
    <p>
      3.7
      开通超级圆桌相应的付费业务需由您本人明确了解并同意，一经支付并确认服务将开始后，不得无故终止。
    </p>
    <p>
      3.8
      超级圆桌是一个信息分享、传播及获取的平台，用户通过超级圆桌发表的信息为公开的信息，其他第三方均可以通过超级圆桌获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在超级圆桌上进行发表。
    </p>
    <p>
      3.9
      用户在超级圆桌上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到超级圆桌的同意。
    </p>
    <p>
      3.10
      为了促进知识的分享和传播，用户将其在超级圆桌上发表的全部内容，授予超级圆桌免费的、不可撤销的、非独家使用许可，超级圆桌有权将该内容用于超级圆桌各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。
    </p>
    <p>
      3.11
      在超级圆桌上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，超级圆桌有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给超级圆桌或任何第三方造成损失的，用户应负责全额赔偿。
    </p>
    <p>
      3.12
      超级圆桌有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及超级圆桌社区指导原则对侵权信息进行处理。
    </p>
    <p>
      3.13
      对于会员因第三方的行为或不作为造成的损失，超级圆桌不承担任何责任，包括但不限于支付服务和网络接入服务、任意第三方的侵权行为。
    </p>
    <p>
      3.14您在使用超级圆桌的服务时，必须遵守中华人民共和国的宪法和法律，禁止利用超级圆桌进行任何违法或者不当行为，包括但不限于下列行为，不得上传、展示、传播或以其他方式传送包含下列内容之一：
    </p>
    <p class="textIndent item">
      (1) 反对宪法所确定的基本原则或违反宪法或法律法规规定的。
    </p>
    <p class="textIndent item">
      (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。
    </p>
    <p class="textIndent item">(3) 损害国家荣誉和利益的，损害公共利益的。</p>
    <p class="textIndent item">(4) 煽动民族仇恨、民族歧视，破坏民族团结的。</p>
    <p class="textIndent item">(5) 破坏国家宗教政策，宣扬邪教和封建迷信的。</p>
    <p class="textIndent item">(6) 散布谣言，扰乱社会秩序，破坏社会稳定的。</p>
    <p class="textIndent item">
      (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。
    </p>
    <p class="textIndent item">(8) 侮辱或者诽谤他人，侵害他人合法权益的。</p>
    <p class="textIndent item">
      (9)
      含有虚假、有害、胁迫、侵犯他人隐私以及骚扰、中伤、粗俗、猥亵或其他违反道德、公序良俗的内容。
    </p>
    <p class="textIndent item">(10) 含有法律、行政法规禁止的其他内容的。</p>
    <p>3.15 不得利用超级圆桌进行任何非法的目的且不得从事以下活动：</p>
    <p class="textIndent item">
      (1) 未经允许，进入计算机信息网络使用计算机信息网络资源。
    </p>
    <p class="textIndent item">
      (2) 未经允许，对计算机信息网络功能进行删除、修改或者增加。
    </p>
    <p class="textIndent item">
      (3)
      未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加。
    </p>
    <p class="textIndent item">(4) 故意制作、传播计算机病毒等破坏性程序。</p>
    <p class="textIndent item">(5) 其他危害计算机信息网络安全的行为。</p>
    <p>3.16 未经允许以复制、抓取、传播等方式对超级圆桌的信息进行商业性活动。</p>
    <p>
      3.17 不得使用任何装置、软件以及恶意程序干扰超级圆桌的服务或试图阻碍超级圆桌的正常运行。
    </p>
    <p>3.18 不得提交虚假订单或进行任何虚假交易，扰乱超级圆桌的正常秩序。</p>
    <p>
      3.19 若您违反本协议或从事侵犯他人权利（包括但不限于发布对他人威胁、诽谤、恐吓、淫秽或令人反感的不当内容或信息）的行为，导致本网站遭受来自第三方的纠纷、投诉、诉讼、索赔或其他损失的，您同意对以上损失全部赔偿并使本网站免受任何损害。
    </p>
    <p>
      3.20 本网站有权对您的上述行为采取相应措施，包括但不限于删除您所发布或上传的内容、暂停您的使用、限制您的使用、终止服务、追究相应的法律责任。本网站也将视司法机关的要求配合其进行相关调查。
    </p>
    <p class="subtitle">
      第四条 &nbsp;&nbsp;&nbsp;&nbsp;协议更新及用户关注义务
    </p>
    <p>
      4.1
      超级圆桌会根据市场需求、产品形态及服务类型的变化等，对现有超级VIP会员服务内容、服务费用、收费方式进行调整，调整后的各项服务自公布之日起生效。会员有效期内的会员服务费用将不受影响，当会员服务有效期届满后，若用户需要续费，则需按照调整后的收费标准支付相应的会员服务费用。
    </p>
    <p>
      4.2
      您需充分了解并同意，由于互联网服务的特殊性，超级圆桌可能会按照相关法规、双方约定或在其他必要时，中断或终止向您提供的服务。届时，超级圆桌会依法保护您的合法权益。
    </p>
    <p class="subtitle">第五条 &nbsp;&nbsp;&nbsp;&nbsp;个人信息保护</p>
    <p>
      5.1 超级圆桌尊重会员的隐私权，不会公开、编辑或泄露任何有关会员的个人资料以及会员在使用网络服务时存储在本网站的非公开内容，但以下情况除外：
    </p>
    <p class="textIndent item">(1) 事先获得会员的明确授权。</p>
    <p class="textIndent item">(2) 遵守法律规定或本网站的合法服务程序。</p>
    <p class="textIndent item">(3) 按照相关政府主管部门的合理要求。</p>
    <p class="textIndent item">(4) 维护社会公众利益。</p>
    <p class="textIndent item">(5) 维护本站的合法权益。</p>
    <p class="textIndent item">(6) 符合其他合法要求的。</p>
    <p>
      以上内容您本人完全同意并接受，为了向您提供更为全面和优质的服务以及产品，超级圆桌可能需要与第三方进行合作，您理解并同意超级圆桌有权将您的必要信息资料提供给该第三方（仅限于更好的为您提供服务）。
    </p>
    <p>
      5.2 您同意超级圆桌对您使用超级圆桌的产品和服务的情况进行数据分析、整理和使用。
    </p>
    <p class="subtitle">第六条 &nbsp;&nbsp;&nbsp;&nbsp;法律管辖和适用</p>
    <p>
      6.1
      本协议适用中华人民共和国大陆地区法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按照法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
    </p>
    <p>
      6.2
      如使用会员服务过程中出现纠纷，您与超级圆桌应友好协商解决，若协商不成，应将纠纷或争议提交本超级圆桌所在地的人民法院进行诉讼。本协议的解释、效力和执行等有关问题均适用中华人民共和国法律。
    </p>
    <p>
      6.3
      超级圆桌向会员提供的全部服务，均仅限于在超级圆桌平台使用，任何以恶意破解等非法手段将超级圆桌提供的服务内容与超级圆桌平台分离的行为，均不属于本协议中约定的超级圆桌提供的服务。由此引起的一切法律后果由行为人负责，超级圆桌将依法追究行为人的法律责任。
    </p>
    <p>
      6.4
      若发现会员有违反本协议的任何行为，导致任何第三方受到损害，会员应当独立承担责任。超级圆桌因此遭受的损失，会员应当一并赔偿。
    </p>
    <p class="subtitle">第七条 &nbsp;&nbsp;&nbsp;&nbsp;其他</p>
    <p>7.1【协议的生效】您使用本服务即视为已阅读并同意受本协议的约束。</p>
    <p>
      7.2【适用法律】本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
    </p>
    <p>
      7.3【争议解决】若会员和超级圆桌之间发生任何纠纷或争议，首先应友好协商解决：协商不成的，会员同意将纠纷或争议提交本协议签订地有管辖权的人民法院。本协议的解释、效力和执行等有关问题均适用中华人民共和国法律。
    </p>
    <p>
      7.4【条款标题】本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。
    </p>
    <p>
      7.5【条款效力】本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
    </p>
    <p>
      7.6【服务范围】超级圆桌仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由会员自行负担。
    </p>
    <p>
      7.7【服务期限】会员服务提供的资源都有固定的服务期限，一旦成为会员即视为默认服务期限。会员服务周期不会因您在期间对产品的中断、终止等情况而延长。
    </p>
    <div class="bottom">© 2019 超级圆桌</div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.protocol_container {
  padding: 30px 20px;
  p {
    margin-top: 30px;
    letter-spacing: 2px;
    line-height: 1.5;
    font-size: 28px;
  }
  .textIndent {
    text-indent: 2em;
  }
  .title {
    font-size: 32px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .subtitle {
    text-indent: 0;
    font-weight: bold;
  }

  .item {
    font-size: 28px;
    margin-top: 2px;
  }

  .bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
  }
}
</style>
