<template>
  <div id="app">
    <VideoPlayerContainer />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        >
        <!-- 这里是会被缓存的视图组件 -->
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
      >
      <!-- 这里是不被缓存的视图组件 -->
    </router-view>

    <van-popup v-model="showRule" class="rule-popup">
      <div class="rule-wrap">
        <UploadRule />
      </div>
      <van-icon
        name="close"
        color="#fff"
        size="26px"
        @click="showRule = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setWxConfig } from '@/common'
import VideoPlayerContainer from '@/components/VideoPlayerContainer'
import UploadRule from '@/components/UploadRule'
import { storageKeys } from '@/env'

export default {
  components: {
    VideoPlayerContainer,
    UploadRule
  },
  data() {
    return {
      showRule: false
    }
  },
  computed: {
    ...mapState('video', ['playList'])
  },
  async mounted() {
    window.$appVm = this
    this.initStore()
    const authOk = await this.$Auth()
    if (!authOk) return
    if (!localStorage[storageKeys.uuid]) {
      const res = await this.$axios.get('/video/v1/users/tmp_get_uuid', {})
      localStorage.setItem(storageKeys.uuid, res.data.uuid)
    }
    // 已授权
    const uuid = localStorage.getItem(storageKeys.uuid)
    if (uuid) {
      setTimeout(() => {
        window.sensors.login(uuid)
      }, 0)
    }
    setTimeout(() => {
      setWxConfig()
    }, 100)
  },
  methods: {
    initStore() {
      const query = this.$route.query
      if (query.suuid) {
        this.$store.commit('setSuuid', query.suuid)
      }
      if (query.gzh) {
        this.$store.commit('setGzh', query.gzh)
      }
    }
  }
}
</script>

<style lang="less">
.topmdrt-ad-manager_tab-wrap a:nth-child(2) {
  display: none;
}
.topmdrt-ad-manager_tab-link--active {
  width: 100%;
  flex-basis: auto !important;
}
@import './assets/less/base.less';
body {
  background: #f4f6f7;
}
#app {
  width: 100vw;
  position: relative;
}
</style>

<style lang="less" scoped>
.rule-popup {
  width: 550px;
  background: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  .rule-wrap {
    padding: 20px;
    background: #fff;
    height: 80vh;
    overflow: scroll;
    margin-bottom: 20px;
    border-radius: 10px;
  }
}
</style>
