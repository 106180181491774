import { PUBLIC_URL, PUBLIC_ORIGIN } from '@/env'

const sslTokenFile =
  process.env.VUE_APP_DEPLOYMENT_ENV === 'production'
    ? 'ssl-token.html'
    : 'ssl-token-test.html'

export default function msg2SingleAuth(messageStr) {
  const authFrameURL = PUBLIC_URL + '/' + sslTokenFile
  // 本地调试用，调试时请注释上一行并修改本地IP地址
  // const PUBLIC_ORIGIN = 'http://192.168.5.200:8080'
  // const authFrameURL = PUBLIC_ORIGIN + '/' + sslTokenFile
  return new Promise((resolve, reject) => {
    let resolved = false
    const receiveMsg = e => {
      // eslint-disable-next-line no-console
      if (e.origin === PUBLIC_ORIGIN) {
        try {
          const m = JSON.parse(e.data)
          if (m.type === 'singleAuthMessageReturn') {
            console.log('receive message from [ssl-token]', e)
            resolved = true
            resolve(e.data)
            window.removeEventListener('message', receiveMsg)
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    }
    const frameId = 'ssl-token-iframe'
    let frameEl = document.getElementById(frameId)
    if (!frameEl) {
      frameEl = document.createElement('iframe')
      frameEl.id = frameId
      frameEl.hidden = 'hidden'
      frameEl.src = authFrameURL
      frameEl.onload = function() {
        frameEl.contentWindow.postMessage(messageStr, '*')
      }
      document.body.append(frameEl)
    } else {
      frameEl.contentWindow.postMessage(messageStr, '*')
    }
    window.addEventListener('message', receiveMsg)
    // 设置2s超时
    setTimeout(() => {
      if (!resolved) {
        window.removeEventListener('message', receiveMsg)
        reject(new Error('msg2SingleAuth failed: executed over 2000ms'))
      }
    }, 2000)
  })
}
