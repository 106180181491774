<template>
  <div
    class="v-container-wrap"
    :class="{
      hidden: !showVideoPage
    }"
  >
    <VideoPlayerUI
      ref="videoPlayerUI"
      :paused-icon="pausedIcon"
      :consultation-icon="consultationIcon"
      :weixin-icon="weixinIcon"
      :text-icon="textIcon"
      :v-loading="vLoading"
      :is-playing="isPlaying"
      :active-index="activeIndex"
      :next-active-index="nextActiveIndex"
      :list="playList"
      :swiper="swiper"
      :pause-tap-animation="pauseTapAnimation"
      :video-info-map="videoInfoMap"
      :play-progress="playProgress"
      @togglePlay="togglePlay"
      @clickButton="onClickButton"
    />
    <van-popup
      v-if="curVideoInfo"
      v-model="showQrPopup"
      class="qr-popup"
    >
      <div
        v-if="curVideoInfo.wx_qrcode_url"
        class="qr-container"
      >
        <img
          :src="curVideoInfo.wx_qrcode_url"
          class="qr"
        />
        <p>欢迎加我微信咨询保险</p>
      </div>
      <div
        v-else
        class="qr-container"
      >
        <img
          src="../assets/img/no-qr.png"
          class="no-qr"
        />
        <p>您尚未设置二维码</p>
        <VButton @click="toSetWxQr">马上设置</VButton>
      </div>
      <PopupCloseIcon @click="showQrPopup = false" />
    </van-popup>
    <van-popup
      v-model="showShareTextPopup"
      position="bottom"
      class="share-text-popup"
    >
      <div class="share-text-container">
        <div class="share-text-popup-title">
          <span>视频配文</span>
          <van-icon
            class="cross-icon"
            name="cross"
            @click="showShareTextPopup = false"
          />
        </div>
        <div class="share-text-popup-main">
          <p class="warning">提示：配文按钮仅自己可见</p>
          <p id="copy-share-text-content">
            {{ curVideoInfo ? curVideoInfo.circle_text : '' }}
          </p>
        </div>
        <VButton
          id="copy-share-text-btn"
          class="copy-share-text-btn"
          data-clipboard-target="#copy-share-text-content"
        >一键复制</VButton>
<!--        <img-->
<!--          class="copy-success"-->
<!--          src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/bdtt/bdtt-rebuild-2/copy-success.png?v=1.0"-->
<!--          alt=""-->
<!--        />-->
      </div>
    </van-popup>

    <!-- 关注弹窗 -->
    <van-popup
      v-model="showFollowPopup"
      class="follow-popup"
    >
      <div class="follow-qr-bg">
        <div class="follow-qr">
          <img
            v-if="followQrURL"
            :src="followQrURL"
          />
        </div>
        <p class="follow-hint">长按扫描二维码</p>
        <p class="follow-hint follow-hint-em">领取带你名片的视频</p>
      </div>
      <PopupCloseIcon @click="showFollowPopup = false" />
    </van-popup>
    <!-- 个人信息弹窗 -->
    <van-popup
      v-model="showAgentCard"
      position="bottom"
      round
      class="agent-card-popup"
    >
      <div
        v-if="curVideoInfo"
        class="agent-card-container"
      >
        <div class="agent-info-wrap">
          <img
            class="agent-avatar"
            :src="curVideoInfo.avatar"
          />
          <div class="agent-info">
            <p class="agent-name">
              {{ curVideoInfo.company ? curVideoInfo.company + ' ' : ''
              }}{{ curVideoInfo.name }}
            </p>
            <p
              v-if="curVideoInfo.phone"
              class="agent-phone"
            >
              {{ curVideoInfo.phone }}
            </p>
          </div>
        </div>
        <div class="agent-desc">{{ curVideoInfo.introduce }}</div>
        <div class="agent-btns">
          <a @click="toWeiZhan">了解我更多</a>
          <a @click="switchMyCard">换成我的名片</a>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Icon, Toast, Dialog } from 'vant'
import { mapState } from 'vuex'
import Swiper from 'swiper'
import moment from 'moment'
import ClipboardJS from 'clipboard'
import VideoPlayerUI from './VideoPlayerUI'
import VButton from './VButton'
import PopupCloseIcon from './PopupCloseIcon'
import { setWxShare } from '@/common/index'
import pausedIcon from '@/assets/img/paused.png'
import consultationIcon from '@/assets/img/consultation.png'
import weixinIcon from '@/assets/img/weixin.png'
import textIcon from '@/assets/img/text.png'
import {
  tcAppID,
  staticURL,
  whovmURL,
  aiSecretaryURL,
  PURCHASE_PUBLIC_URL,
  storageKeys,
  bdttURL
} from '@/env'
import { formatDate } from '@/common/util'
Vue.use(Popup)
  .use(Icon)
  .use(Toast)

export default {
  name: 'VideoPlayerContainer',
  components: { VideoPlayerUI, VButton, PopupCloseIcon },
  data() {
    return {
      pausedIcon,
      consultationIcon,
      weixinIcon,
      textIcon,
      pauseTapAnimation: false,
      vLoading: false,
      nextActiveIndex: 0,
      swiper: null,
      showQrPopup: false,
      showShareTextPopup: false, //分享弹框
      videoDuration: null,
      videoPlayTime: 0,
      // 记录视频初始化加载时长用
      videoLoadTimeLog: null,

      showAgentCard: false,
      showFollowPopup: false,
      followQrURL: null
    }
  },
  computed: {
    ...mapState('video', [
      'showVideoPage',
      'globalVideoPlayer',
      'isPlaying',
      'userWillingToPlay',
      'playList',
      'playPagination',
      'activeIndex',
      'videoInfoMap',
      'entrance'
    ]),
    uid() {
      if (!this.playList) return null
      if (!this.playList[this.activeIndex]) return null
      const uid = this.playList[this.activeIndex].uid
      console.log(`[debug] uid changed to ${uid}, index: ${this.activeIndex}`)
      return uid
    },
    vid() {
      if (!this.playList) return null
      if (!this.playList[this.activeIndex]) return null
      return this.playList[this.activeIndex].vid
    },
    curVideoInfo() {
      if (!this.uid) return null
      return this.videoInfoMap[this.uid]
    },
    playProgress() {
      if (!this.videoDuration) return 0
      const perStr = (this.videoPlayTime / this.videoDuration).toFixed(4)
      return parseFloat(perStr)
    }
  },
  created() {
    if (this.$route.query.alias == 'showPopup') {
      this.showShareTextPopup = true
    }
  },
  async mounted() {
    Vue.prototype.$videoPlayerContainer = this
    this.initSwiper()
    this.initClipboard()
    let initVid = this.$route.query.tcVid
    if (!initVid && this.playList && this.playList[0]) {
      initVid = this.playList[0].vid
    }
    if (!initVid) initVid = '243791579315284209'
    if (initVid) {
      this.$store.dispatch('video/initGlobalPlayer', {
        vid: initVid,
        elementId: 'global-player'
      })
      this.startCalcPlayProgress()
    }
  },
  beforeDestroy() {
    if (this.playProgressCalculator) {
      clearInterval(this.playProgressCalculator)
      this.playProgressCalculator = null
    }
  },
  methods: {
    setWx(uid) {
      const videoInfo = this.videoInfoMap[uid]
      if (!videoInfo) {
        setWxShare()
        return
      }
      const _this = this

      const shareConfig = {
        title:
          videoInfo.title ||
          '我在圆桌保视发现了一个有趣的保险视频，推荐你收看！',
        desc: videoInfo.video_desc || '圆桌保视助您发现更多精彩生活',
        link: videoInfo.share_url,
        imgUrl: videoInfo.image_url || staticURL + '/yzbs-icon.png'
      }

      console.log('[DEBUG] setWxShare')
      console.log(JSON.stringify(shareConfig, null, ' '))
      setWxShare({
        ...shareConfig,
        success(res) {
          if (
            res.errMsg === 'sendAppMessage:ok' ||
            res.errMsg === 'onMenuShareAppMessage:ok'
          ) {
            _this.$sensorsTrack('PageShare', {
              page_type: '视频',
              video_id: videoInfo.vid,
              video_title: videoInfo.title,
              is_author_share: !!videoInfo.card_is_self,
              share_method: '分享朋友'
            })
            setTimeout(() => {
              _this.logShareVideo({
                type: 1,
                uid
              })
            })
          } else {
            _this.$sensorsTrack('PageShare', {
              page_type: '视频',
              video_id: videoInfo.vid,
              video_title: videoInfo.title,
              is_author_share: !!videoInfo.card_is_self,
              share_method: '分享朋友圈'
            })
            setTimeout(() => {
              _this.logShareVideo({
                type: 2,
                uid
              })
            })
          }
        }
      })
    },
    logShareVideo(data) {
      this.$axios.post('/video/v1/share/share_video', {
        ...data,
        entrance: this.entrance,
        suuid: this.$store.state.suuid
      })
    },
    async requestVideoInfo(uid) {
      const res = await this.$store.dispatch('video/getVideoDetail', {
        uid
      })
      if (res === 2003) {
        return res
      }
      if (res) {
        if (res.card_is_self) {
          Toast({
            message: '已更换为你的名片',
            duration: 1000
          })
        }
      }
      this.setWx(uid)
    },
    initSwiper() {
      const vm = this
      // 注意：new Swiper 选项内的'this'指的是swiper示例而非当前vue实例
      // 以下用'vm'指向了当前vue实例
      new Swiper('#swiper-container', {
        initialSlide: this.activeIndex,
        direction: 'vertical',
        on: {
          init() {
            vm.swiper = this
            window.__videoSwiper = this
          },
          // slideChange事件 将会在 transitionEnd事件之前触发
          slideChange() {
            console.log(
              '[debug] slideChange, activeIndex(slider)',
              this.activeIndex
            )
            vm.pause()
            vm.slideChanged = true
            vm.nextActiveIndex = this.activeIndex
            document.title = vm.playList[this.activeIndex].title
            const uid = vm.playList[this.activeIndex].uid
            if (window.__noRequestVideoInfoInSlideChange) {
              window.__noRequestVideoInfoInSlideChange = false
            } else {
              vm.requestVideoInfo(uid)
            }
            // 神策记录视频滑动
            if (vm.showVideoPage) {
              const action =
                this.activeIndex > vm.activeIndex ? '视频下滑' : '视频上滑'
              vm.$sensors.quick(
                'trackAllHeatMap',
                document.getElementById('swiper-container'),
                {
                  $element_content: action
                }
              )
            }
          },
          transitionEnd() {
            if (vm.slideChanged) {
              console.log(
                '[debug] transitionEnd, activeIndex(slider)',
                this.activeIndex
              )
              vm.onSlideChangeEnd(this.activeIndex)
              this.slideChanged = false
            }
          }
        }
      })
    },
    async onSlideChangeEnd(idx) {
      this.$refs.videoPlayerUI.hidePlayer()
      const player = this.globalVideoPlayer
      let loadingIdx = idx
      player.$promisifyEvent('one', 'loadedmetadata').then(async () => {
        if (loadingIdx === this.nextActiveIndex) {
          console.log('[debug] changeSuccessFn, activeIndex(vm)', idx)
          this.$store.commit('video/setActiveIndex', idx)
          if (this.userWillingToPlay) {
            this.play({ trigger: 'AUTOPLAY' })
          }
        }
      })
      if (this.userWillingToPlay) {
        this.vLoading = true
      }
      this.videoLoadTimeLog = {
        uid: this.playList[idx].uid,
        loadStartTime: Date.now()
      }
      setTimeout(async () => {
        if (idx === this.playList.length - 1) {
          if (this.playPagination.finished) {
            return
          }
          await this.$store.dispatch('video/loadNextPage')
          await this.$nextTick()
          this.swiper.update()
        }
      })
      // 切换player视频
      player.loadVideoByID({
        fileID: this.playList[idx].vid,
        appID: tcAppID
      })
    },
    togglePlay() {
      this.$sensors.quick(
        'trackAllHeatMap',
        document.getElementById('global-player'),
        {
          $element_content: this.isPlaying ? '暂停播放视频' : '开始播放视频'
        }
      )
      this.isPlaying ? this.pause() : this.play({ trigger: 'CLICK' })
      this.$store.commit('video/setUserWillingToPlay', this.isPlaying)
    },
    play(opt = {}) {
      if (!this.isPlaying) {
        const unWatch = this.$watch('isPlaying', bool => {
          if (bool) {
            if (this.videoLoadTimeLog) {
              if (this.videoLoadTimeLog.uid === this.uid) {
                this.videoLoadTimeLog.playingTime = Date.now()
                this.logVideoLoadTime(opt.trigger)
              } else {
                this.videoLoadTimeLog = null
              }
            }
            setTimeout(() => {
              this.vLoading = false
              this.$refs.videoPlayerUI.showPlayer()
            }, 100)
          }
          unWatch()
        })
      } else {
        this.vLoading = false
        this.$refs.videoPlayerUI.showPlayer()
        setTimeout(() => {
          this.vLoading = false
          this.$refs.videoPlayerUI.showPlayer()
        }, 100)
      }
      if (this.videoLoadTimeLog) {
        if (this.videoLoadTimeLog.uid === this.uid) {
          this.videoLoadTimeLog.playStartTime = Date.now()
        } else {
          this.videoLoadTimeLog = null
        }
      }
      this.$store.dispatch('video/play', true)
    },
    pause() {
      if (!this.pauseTapAnimation) this.pauseTapAnimation = true
      this.$store.dispatch('video/play', false)
    },
    logVideoLoadTime(trigger) {
      const entrance = this.entrance
      let start_time = this.videoLoadTimeLog.playStartTime
      if (trigger === 'AUTOPLAY') {
        start_time = this.videoLoadTimeLog.loadStartTime
      }
      const end_time = this.videoLoadTimeLog.playingTime
      const log_data = {
        business_id: this.videoLoadTimeLog.uid,
        business_type: '视频项目视频',
        start_time: formatDate(
          moment(start_time).toDate(),
          'yyyy-MM-dd hh:mm:ss.S'
        ),
        end_time: formatDate(
          moment(end_time).toDate(),
          'yyyy-MM-dd hh:mm:ss.S'
        ),
        total_load_time: (end_time - start_time) / 1000,
        entrance
      }
      this.videoLoadTimeLog = null
      this.$sensorsTrack('LoadTime', log_data)
    },
    onClickButton(event, buttonType) {
      switch (buttonType) {
        case 'showWxQrcode':
          this.trackSensorsClick(event, buttonType)
          this.showQrPopup = true
          break
        case 'showShareText':
          this.trackSensorsClick(event, buttonType)
          this.showShareTextPopup = true
          break
        case 'switchMyCard':
          this.trackSensorsClick(event, buttonType)
          this.switchMyCard()
          break
        case 'freeSuggestion':
          this.trackSensorsClick(event, buttonType)
          if (!this.curVideoInfo || !this.curVideoInfo.uuid) return
          setTimeout(() => {
            window.location.href = `${aiSecretaryURL}/#/?agent_uuid=${this.curVideoInfo.uuid}`
          }, 500)
          break
        case 'agentCard':
          this.toWeiZhan()
          break
        case 'complaint':
          if (!this.curVideoInfo) return
          Dialog.confirm({
            title: '提交投诉建议',
            message: '如对视频内容有异议，可提交投诉建议',
            confirmButtonText: '投诉建议',
            confirmButtonColor: '#00B7AE'
          })
            .then(() => {
              window.location.href =
                bdttURL +
                `/#/complaint?from=${encodeURIComponent(
                  window.location.href
                )}&item_type=video&id=${this.curVideoInfo.id
                }&official_open_id=${localStorage.getItem(
                  storageKeys.official_open_id
                )}`
            })
            .catch(() => { })
          break
        default:
          break
      }
    },
    trackSensorsClick(event, buttonType) {
      const nameMap = {
        showWxQrcode: '加我微信',
        showShareText: '视频配文',
        freeSuggestion: '免费咨询',
        switchMyCard: '换成我的名片'
      }
      this.$sensors.quick('trackAllHeatMap', event.target, {
        $element_content: nameMap[buttonType]
      })
    },
    toWeiZhan() {
      if (!this.curVideoInfo || !this.curVideoInfo.uuid) return
      window.location.href =
        whovmURL +
        '/#/microweb?shareUuid=' +
        this.curVideoInfo.uuid +
        '&official_open_id=' +
        localStorage.getItem(storageKeys.official_open_id)
    },
    toSetWxQr() {
      window.location.href =
        whovmURL +
        '/#/baseInfo?official_open_id=' +
        localStorage.getItem(storageKeys.official_open_id)
    },
    async switchMyCard() {
      this.$sensorsTrack('ClickChangeCard', {
        entrance: '视频'
      })
      // 检查订阅
      const gzhMap = {
        1: 'baowen',
        15: 'zybw',
        3: 'poster',
        2: 'zhushouhao',
        30: 'video',
        31: 'vip_video'
      }
      const official_open_id =
        localStorage.getItem(storageKeys.official_open_id) || '2'

      let needFollow = false

      try {
        const res = await this.$axios.post('/video/v1/users/check_subscribe', {
          gzh: gzhMap[official_open_id]
        })
        if (res.errCode === 0) {
          const isSubscribe = res.data.subscribe
          if (isSubscribe !== 1) {
            window.location.href = res.data.subscribe_url
            return
            // needFollow = true
          }
        } else {
          window.location.href = res.data.subscribe_url
          return
          // needFollow = true
        }
      } catch (error) {
        // needFollow = true
      }

      // if (needFollow) {
      //   const res = await this.$axios.get(
      //     '/video/v1/column/get_subscribe_qrcode'
      //   )
      //   if (res.errCode === 0) {
      //     this.followQrURL = res.data.data
      //     this.showFollowPopup = true
      //   } else {
      //     this.$toast({
      //       message: res.errMsg
      //     })
      //   }
      //   return
      // }

      try {
        const changingCardUid = this.playList[this.nextActiveIndex].uid
        const res = await this.$axios.get('/video/v1/column/change_card', {
          params: {
            uid: changingCardUid,
            suuid: this.$store.state.suuid
          }
        })
        if (res.errCode === 0) {
          const data = res.data
          // 已购买
          if (data.goto === 'video') {
            this.$store.commit('setSuuid', '')
            const query = this.$route.query
            if (query.suuid) {
              this.$router.replace({
                query: { ...query, suuid: '' }
              })
            }
            console.log(
              `[debug] change card request video info, uid: ${changingCardUid}, index: ${this.nextActiveIndex}`
            )
            this.requestVideoInfo(changingCardUid)
          }
          // 需要购买
          if (data.goto === 'order') {
            // 购买页需要去到特定的支付域名
            const token = localStorage.getItem(storageKeys.token)
            const uuid = localStorage.getItem(storageKeys.uuid)
            window.location.href = `${PURCHASE_PUBLIC_URL}/#/purchase?uid=${changingCardUid}&suuid=${data.suuid
              }&token=${token}&uuid=${uuid}&official_open_id=${localStorage.getItem(
                storageKeys.official_open_id
              )}`
          }
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
        }
      } catch (error) {
        Toast('操作失败')
        console.error('换成我的名片信息拉取失败', error)
      }
    },
    initClipboard() {
      const vm = this
      const clipboard = new ClipboardJS('#copy-share-text-btn')
      clipboard.on('success', function () {
        vm.showShareTextPopup = false
        Toast({
          message: '复制成功',
          type: 'success',
          duration: 1500
        })
      })
    },
    startCalcPlayProgress() {
      this.playProgressCalculator = setInterval(this.calcPlayProgress, 500)
      this.watchPlayEnd()
    },
    watchPlayEnd() {
      const cb = () => {
        const video = this.curVideoInfo
        const uuid = video.uuid
        if (uuid !== localStorage.getItem(storageKeys.uuid)) {
          // 视频结束播放时弹出个人介绍弹窗
          this.showAgentCard = true
        }

        this.globalVideoPlayer.play()
        this.globalVideoPlayer.$promisifyEvent('one', 'ended').then(cb)
      }

      this.globalVideoPlayer.$promisifyEvent('one', 'ended').then(cb)
    },
    calcPlayProgress() {
      if (!this.globalVideoPlayer) return
      try {
        this.videoDuration = this.globalVideoPlayer.duration()
        this.videoPlayTime = this.globalVideoPlayer.currentTime()
      } catch (error) {
        console.error('calcPlayProgress failed', error)
      }
    }
  }
}
</script>

<style lang="less">
.v-container-wrap {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0vw;
  overflow: hidden;
}
.hidden {
  height: 0;
  .v-player-scroller {
    top: -100%;
  }
}
.qr-popup {
  background: none !important;
  .qr-container {
    width: 400px;
    height: 464px;
    background: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .no-qr {
      width: 204px;
      height: 188px;
      margin-top: 72px;
    }
    .qr {
      width: 298px;
      height: 298px;
      margin-top: 60px;
      margin-bottom: 14px;
    }
    > p {
      font-size: 28px;
      font-weight: bold;
      color: #29292f;
      margin-bottom: 28px;
    }
  }
}
.share-text-popup {
  background: none !important;
  .share-text-container {
    background: #fff;
    .share-text-popup-title {
      height: 96px;
      line-height: 96px;
      font-size: 32px;
      color: #29292f;
      text-align: center;
      position: relative;
      background: #f4f6f7;
      position: relative;
      .cross-icon {
        position: absolute;
        font-size: 28px;
        padding: 28px;
        right: 8px;
        top: 6px;
      }
    }
    .share-text-popup-main {
      padding: 22px 50px;
      > p {
        font-size: 28px;
        line-height: 40px;
      }
      .warning {
        color: #ff6155;
        margin-bottom: 12px;
      }
    }
    .copy-share-text-btn {
      width: 672px;
      margin-left: 39px;
      margin-top: 24px;
      margin-bottom: 36px;
      margin-bottom: calc(36px + constant(safe-area-inset-bottom));
      margin-bottom: calc(36px + env(safe-area-inset-bottom));
    }
  }
}
</style>

<style lang="less" scoped>
.agent-card-popup {
  padding: 60px 20px 80px;
  .agent-info-wrap {
    display: flex;
    align-items: center;
    padding: 0 10px;
    .agent-avatar {
      width: 122px;
      height: 122px;
      object-fit: cover;
      border: 2px solid #59b4a9;
      border-radius: 50%;
    }
    .agent-info {
      margin-left: 20px;
      .agent-name {
        font-weight: bold;
        font-size: 36px;
        color: #0c0c0c;
      }
      .agent-phone {
        color: #666;
        font-size: 32px;
        margin-top: 8px;
      }
    }
  }
  .agent-desc {
    font-size: 32px;
    color: #666;
    margin-top: 50px;
    padding: 0 10px;
  }

  .agent-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    > a {
      display: block;
      width: 344px;
      height: 98px;
      color: #333;
      line-height: 98px;
      text-align: center;
      font-size: 36px;
      background-color: #f4bf60;
      border-radius: 50px;
    }
    > a:nth-of-type(2) {
      background-color: #59b4a9;
      color: #fff;
    }
  }
}

.follow-popup {
  width: 454px;
  background: none;
  .follow-qr-bg {
    height: 560px;
    background-image: url(../assets/img/popup/follow-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 150px;
  }
  .follow-qr {
    height: 280px;
    overflow: visible;
    position: relative;
    img {
      position: absolute;
      width: 220px;
      height: 220px;
      box-sizing: content-box;
      top: 0;
      left: 0;
      padding: 30px 116px;
      padding-bottom: 150px;
    }
  }
  .follow-hint {
    text-align: center;
    font-size: 32px;
    color: #666;
  }
  .follow-hint-em {
    font-weight: bold;
    color: #ec7c48;
  }
}

.copy-success {
  width: 604px;
  height: 288px;
  position: fixed;
  top: 36px;
  right: 30px;
  z-index: 9999;
}
</style>
