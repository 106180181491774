import Vue from 'vue'
import Vuex from 'vuex'
import video from './video'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    suuid: '',
    gzh: ''
  },
  mutations: {
    setSuuid(state, suuid) {
      state.suuid = suuid
    },
    setGzh(state, id) {
      state.gzh = id
    }
  },
  actions: {},
  modules: {
    video
  }
})
