<template>
  <div class="square-container" :class="{ 'lock-scroll': showGuide }">
    <SquareGuide v-if="showGuide" @finish="onGuideFinish" />
    <div class="fixed-tabs">
      <van-tabs
        v-model="activeTabIdx"
        line-width="20px"
        line-height="2px"
        color="#29292F"
        title-active-color="#000"
        title-inactive-color="#9B9B9B"
        :swipe-threshold="5"
        @change="onTabChange"
      >
        <van-tab v-for="tab in tabs" :key="tab.column_id">
          <div slot="title" class="custom-tab">
            <span>{{ tab.column_name }}</span>
            <img v-if="tab.hot" src="../assets/img/hot.png" class="hot-tab" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!--    <div class="search-bar-wrap">-->
    <!--      <a class="search-bar" @click="toSearch">点此搜索视频</a>-->
    <!--    </div>-->
    <van-list
      v-if="lists && lists[activeTabIdx]"
      v-model="pagination[activeTabIdx].loading"
      :finished="pagination[activeTabIdx].finished"
      :error.sync="pagination[activeTabIdx].error"
      finished-text="没有更多了"
      error-text="加载失败，点击此处重新加载"
      @load="loadListNextPage"
    >
      <div id="masonry-wrap" class="img-link-wrap">
        <a
          v-for="(v, vIndex) in lists[activeTabIdx]"
          :key="v.uid"
          class="img-link"
          sensors-track="true"
          :sensors-data="
            JSONstr({
              $title: '视频广场',
              $element_content: '点击视频',
              video_title: v.title,
              video_id: v.uid,
              column_name: activeTab.column_name,
              column_id: activeTab.column_id
            })
          "
          @click="toVideoDetail(v, vIndex)"
        >
          <div class="bg-panel">
            <div v-if="v.is_top !== 1 && v._isNew" class="is-new-tag">
              新上线
            </div>
            <div
              class="img-aspectratio-wrap"
              aspectratio
              :style="{
                paddingTop: v._aspectratioPaddingTop
              }"
            >
              <div class="img" aspectratio-content>
                <img src="../assets/img/paused.png" class="play-icon" />
                <van-image
                  class="post"
                  fit="cover"
                  lazy-load
                  :src="v.image_url"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="30" />
                  </template>
                </van-image>
                <div class="hot-fire">
                  <span>播放量：{{ v._viewPvSimulate }}</span>
                  <img
                    v-if="v.is_top === 1"
                    src="../assets/img/hot-fire.png"
                    class="hot-fire-icon"
                  />
                </div>
              </div>
            </div>
            <div class="desc">{{ v.title }}</div>
          </div>
        </a>
      </div>
    </van-list>
    <a class="to-upload" @click="toUpload">
      <img src="../assets/img/square-guide/to-upload.png" />
    </a>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Toast, List, Lazyload, Image } from 'vant'
import Masonry from 'masonry-layout'
import { setWxShare } from '@/common/index'
import SquareGuide from '@/components/SquareGuide'
import moment from 'moment'
import { storageKeys, videoPublicURL } from '../env'

// import { staticURL } from '@/env'

Vue.use(Tab)
  .use(Tabs)
  .use(List)
  .use(Lazyload)
  .use(Image)

export default {
  name: 'Square',
  components: {
    SquareGuide
  },
  data() {
    return {
      showGuide: false,
      activeTabIdx: 0,
      tabs: [],
      lists: {},
      pagination: {}
    }
  },
  computed: {
    activeTab() {
      if (!this.tabs[this.activeTabIdx]) return {}
      return this.tabs[this.activeTabIdx]
    }
  },
  watch: {
    activeTabIdx(val, oldVal) {
      this.pagination[oldVal].scroll = window.scrollY
    }
  },
  activated() {
    if (this.pagination[this.activeTabIdx]) {
      setTimeout(() => {
        window.scrollTo(0, this.pagination[this.activeTabIdx].scroll || 0)
        this.setWaterfall()
      }, 60)
    }
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    document.title = '视频广场'
    this.setWx()
    await this.loadTabs()
    if (this.tabs) {
      this.tabs.forEach((t, index) => {
        this.$set(this.pagination, index, {
          page: 0,
          pageSize: 10,
          finished: false,
          loading: false,
          error: false,
          scroll: 0
        })
      })
      await this.loadListNextPage()
      await this.$nextTick()
    }
    this.checkShowGuide()
    window.addEventListener('scroll', this.handleScroll, { passive: false })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, { passive: false })
  },
  beforeDestroy() {
    if (this.$masonry) {
      this.$masonry.destroy()
      this.$masonry = null
    }
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 0) {
        const messageStr = {
          type: 'scroll'
        }
        window.parent.postMessage(JSON.stringify(messageStr), '*')
      }
    },
    setWx() {
      setWxShare()
    },
    async loadTabs() {
      try {
        const res = await this.$axios.get('/video/v1/column/all_columns')
        if (res.errCode === 0) {
          this.tabs = res.data.columns
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
        }
      } catch (error) {
        console.error('拉取栏目列表失败', error)
        Toast('拉取栏目列表失败')
      }
    },
    async loadListNextPage() {
      const curPagination = this.pagination[this.activeTabIdx]
      try {
        curPagination.loading = true
        const nextPage = curPagination.page + 1
        const columnId = this.tabs[this.activeTabIdx].column_id
        const res = await this.$axios.get('/video/v1/column/column_videos', {
          params: {
            column_id: columnId,
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
        if (res.errCode === 0) {
          const appendList = res.data.videos
          appendList.forEach(t => {
            // 对样式进行预处理
            let _feJsonDataParsed = {
              coverAspectRatio: 1
            }
            t._feJsonDataParsed = _feJsonDataParsed
            t._aspectratioPaddingTop =
              (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
            try {
              // 阅读数处理
              t._viewPvSimulate = t.base_view_num * 37 + 10000
              // 新上线视频标识
              const tAfterCreated = Date.now() - moment(t.create_time).valueOf()
              t._isNew = tAfterCreated < 1000 * 60 * 60 * 48
              _feJsonDataParsed = JSON.parse(t.fe_json_data)
              t._feJsonDataParsed = _feJsonDataParsed
              t._aspectratioPaddingTop =
                (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
              // eslint-disable-next-line no-empty
            } catch (error) {}
          })
          let setWaterfallType = ''
          if (!this.lists[this.activeTabIdx]) {
            this.$set(this.lists, this.activeTabIdx, appendList)
            setWaterfallType = 'switchTab'
          } else {
            this.lists[this.activeTabIdx].push(...appendList)
          }
          curPagination.page = nextPage
          if (appendList.length < curPagination.pageSize) {
            curPagination.finished = true
          }
          curPagination.error = false
          await this.setWaterfall(setWaterfallType)
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
          curPagination.error = true
        }
        curPagination.loading = false
      } catch (error) {
        curPagination.loading = false
        curPagination.error = true
        console.error('拉取视频数据失败', error)
        Toast('拉取视频数据失败')
      }
    },
    async setWaterfall(type) {
      if (this.needSetWaterfall) return
      this.needSetWaterfall = true
      if (type === 'switchTab') {
        if (this.$masonry) {
          this.$masonry.destroy()
          this.$masonry = null
        }
      }
      await this.$nextTick()
      await new Promise(resolve => {
        setTimeout(() => {
          if (!this.$masonry) {
            this.$masonry = new Masonry('#masonry-wrap', {
              transitionDuration: 0
            })
          } else {
            this.$masonry.reloadItems()
            this.$masonry.layout()
          }
          this.needSetWaterfall = false
          setTimeout(() => {
            resolve()
          }, 350)
        }, 150)
      })
    },
    async onTabChange() {
      this.$sensorsTrack('ViewColumn', {
        column_id: this.tabs[this.activeTabIdx].column_id,
        column_name: this.tabs[this.activeTabIdx].column_name,
        column_order: this.activeTabIdx + 1,
        page_type: '视频',
        $title: document.title
      })
      const curPagination = this.pagination[this.activeTabIdx]
      if (!this.lists[this.activeTabIdx]) {
        await this.loadListNextPage()
      } else {
        this.setWaterfall('switchTab')
      }
      setTimeout(() => {
        window.scrollTo(0, curPagination.scroll)
      }, 100)
    },
    async toVideoDetail(v, index) {
      // 初始化播放列表
      this.$store.commit('video/setActiveIndex', index)
      this.$store.commit(
        'video/setColumnId',
        this.tabs[this.activeTabIdx].column_id
      )
      this.$store.commit(
        'video/setPlayList',
        JSON.parse(JSON.stringify(this.lists[this.activeTabIdx]))
      )
      this.$store.commit(
        'video/setPlayPagination',
        JSON.parse(JSON.stringify(this.pagination[this.activeTabIdx]))
      )
      this.$store.commit('video/setUserWillingToPlay', true)
      this.$store.commit('video/setEntrance', 'shipinguangchang')
      if (!this.$store.state.video.playedOnce) {
        await this.$store.dispatch('video/initPlay')
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 300)
        })
      } else {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 200)
        })
      }

      window.__videoSwiper.update()
      if (window.__videoSwiper.activeIndex === index) {
        this.$videoPlayerContainer.onSlideChangeEnd(index)
      } else {
        window.__noRequestVideoInfoInSlideChange = true
        window.__videoSwiper.slideTo(index, 0, true)
      }
      this.pagination[this.activeTabIdx].scroll = window.scrollY
      window.scrollTo(0, 0)
      const messageStr = {
        type: 'url',
        value: `${videoPublicURL}video?uid=${v.uid}`
      }
      window.parent.postMessage(JSON.stringify(messageStr), '*')
      // this.$router.push({
      //   path: '/video',
      //   query: {
      //     uid: v.uid
      //   }
      // })
    },
    toUpload() {
      const messageStr = {
        type: 'url',
        value: `${videoPublicURL}upload-video?from=square`
      }
      window.parent.postMessage(JSON.stringify(messageStr), '*')
      // this.$router.push('/upload-video?from=square')
    },
    toSearch() {
      this.$router.push('/search')
    },
    async checkShowGuide() {
      const hadShow = localStorage.getItem(storageKeys.hadShowSquareGuide)
      if (hadShow) return
      try {
        const res = await this.$axios.get('/video/v1/users/is_show_new_tips')
        const show = res.data.show_tip
        if (show === 1) {
          this.showGuide = true
          localStorage.setItem(storageKeys.hadShowSquareGuide, true)
        }
        localStorage.setItem(storageKeys.hadShowSquareGuide, true)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    onGuideFinish() {
      this.showGuide = false
    }
  }
}
</script>

<style lang="less" scoped>
.square-container {
  padding-top: 124px;
  padding-bottom: calc(110px + constant(safe-area-inset-bottom));
  padding-bottom: calc(110px + env(safe-area-inset-bottom));
}

.lock-scroll {
  height: 100vh;
  overflow: hidden;
}

@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  40% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.img-link-wrap {
  width: 736px;
  margin: 7px auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .img-link {
    width: 367.5px;
    flex-basis: 367.5px;
    padding: 7px;
    padding-bottom: 14px;
    animation: enterAnimation 0.4s ease-in-out;
    .bg-panel {
      border-radius: 8px;
      overflow: hidden;
      background: #fff;
      position: relative;
      .is-new-tag {
        width: 96px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background: #ff6155;
        border-radius: 0 0 6px 6px;
        position: absolute;
        top: 0;
        left: 18px;
        z-index: 100;
      }
      .desc {
        color: #29292f;
        font-size: 24px;
        line-height: 36px;
        padding: 14px 20px;
      }
      .img {
        .play-icon {
          position: absolute;
          width: 120px;
          height: 120px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 99;
        }
        .post {
          width: 100%;
          height: 100%;
        }
        .hot-fire {
          display: flex;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          height: 44px;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          padding: 0 12px;
          line-height: normal;
          > span {
            font-size: 22px;
            color: #fff;
          }
        }
        .hot-fire-icon {
          width: 24px;
          height: 28px;
          margin-left: 8px;
        }
      }
    }
  }
}
.custom-tab {
  font-size: 28px;
  position: relative;
  width: auto;
  padding: 0;
  > img {
    position: absolute;
    top: 16px;
    left: 50%;
    width: 32px;
    height: 24px;
    transform: translateX(24px);
  }
}
.fixed-tabs {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 101;
}

.search-bar-wrap {
  width: 750px;
  height: 62px;
  display: block;
  padding: 0 16px;
  background: #f4f6f7;
  .search-bar {
    width: 718px;
    height: 64px;
    font-size: 28px;
    color: #a7a7a7;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(232, 232, 233, 1);
    border-radius: 8px;
  }
}
.to-upload {
  position: fixed;
  right: 12px;
  bottom: 200px;
  img {
    width: 112px;
  }
}
</style>

<style lang="less">
.square-container {
  .van-tabs__wrap,
  .van-tab {
    height: 108px;
    line-height: 108px;
    padding: 0;
  }
  .van-tabs__line {
    bottom: 40px;
  }
  .van-image__error,
  .van-image__loading {
    height: 100%;
  }
  .van-image__error {
    position: relative;
  }
  .van-tab--active {
    .custom-tab {
      font-size: 30px;
    }
  }
}
</style>
