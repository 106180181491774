<template>
  <van-icon class="v-close-icon" name="close" @click="onClick" />
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
.v-close-icon {
  font-size: 48px;
  color: #fff;
  position: relative;
  left: 50%;
  margin-top: 10px;
  transform: translateX(-50%);
  padding: 20px;
}
</style>
