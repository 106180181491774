import Vue from 'vue'
import sensors from 'sa-sdk-javascript'
import { formatDate } from '@/common/util'
import { getStorageKey, storageKeys } from './env'

// 神测数据接口
const saURLs = {
  development: 'https://shenceapi.xiangrikui.com/sa?project=default',
  production: 'https://shenceapi.xiangrikui.com/sa?project=chaozhuo_project'
}
const saURL = saURLs[process.env.VUE_APP_DEPLOYMENT_ENV]

let fullURL = window.location.href
if (fullURL.includes('?')) {
  fullURL = fullURL.split('?')[1].split('&')
  for (let i = 0; i < fullURL.length; i++) {
    const arr = fullURL[i].split('=')
    const _key = getStorageKey(arr[0])
    const _value = arr[1] + ''
    localStorage.setItem(_key, _value)
  }
}

sensors.init({
  server_url: saURL,
  heatmap_url: 'assets/js/sensorsdata/1.14.12/heatmap.min.js',
  heatmap: {
    // 是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
    clickmap: 'default',
    // 是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
    scroll_notice_map: 'default',
    // //此参数针对预置 $WebClick 事件（包括 quick('trackHeatMap') quick('trackAllHeatMap') 触发的）生效
    custom_property: function (element_target) {
      let data = element_target.getAttribute('sensors-data')
      if (data) {
        try {
          data = JSON.parse(data)
          return data
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    }
  }
})
// 神策公共属性
sensors.registerPage({
  platform: 'h5',
  platform_type: 'c',
  official_open_id: parseInt(localStorage[storageKeys.official_open_id])
})

const throttleTimeRecord = {}
// 限制点击事件的上报频率
const throttledTrack = (event_name, properties = {}, callback) => {
  if (
    throttleTimeRecord[event_name] &&
    Date.now() - throttleTimeRecord[event_name] < 300
  ) {
    return
  }

  throttleTimeRecord[event_name] = Date.now()
  const curr = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
  sensors.track(event_name, { event_time: curr, ...properties }, callback)
}

// 某些事件无法即时获取所有数据，则存入缓存队列中，待获取到事件再发送
Vue.prototype.$sensorsTodoTracks = []

window.sensors = Vue.prototype.$sensors = sensors
window.sensorsTrack = Vue.prototype.$sensorsTrack = throttledTrack

export default sensors
