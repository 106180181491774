/* eslint-disable no-console */
import axios from 'axios'
import { common } from '@topmdrt/common-sdk'
import { tokenAuthURL, storageKeys, getStorageKey } from '@/env.js'
import msg2SingleAuth from './singleAuth'
/**
 * 清除微信干扰参数
 * 返回布尔值：是否更改了url
 */
export function cleanWxURLParams() {
  const { matched, url } = common.urlCleanWechatParams(window.location.href)
  if (matched) {
    window.location.href = url
  }
  return matched
}

/**
 * 清除授权敏感参数
 * 返回处理后的url
 */
export function cleanURLAuthParams(originalURL) {
  return common.urlCleanSpecifiedParams(originalURL || window.location.href, [
    'token',
    'uuid'
  ])
}

/**
 * 跳转授权
 * authServURL: 授权链接
 * redirectURLParamName: 回调地址参数名
 */
export function gotoAuth(authServURL, redirectURLParamName) {
  if (window._isAuthing) return
  window._isAuthing = true
  let redirectURL = window.location.href
  const reg = /\?(.)*#/g
  const match = window.location.href.match(reg)
  if (match) {
    redirectURL = window.location.href.replace(reg, '#')
  }
  // 去除授权参数
  redirectURL = cleanURLAuthParams(redirectURL)
  redirectURL = common.urlBase64Encode(redirectURL)

  window.$appVm.$router.push({
    path: '/auth',
    query: {
      authServURL,
      redirectURLParamName,
      redirectURL
    }
  })
  // axios
  //   .get(`${authServURL}?${redirectURLParamName}=${redirectURL}`)
  //   .then(res => {
  //     if (res.data.errCode === 0) {
  //       window.location.href = res.data.data.auth_url
  //     }
  //   })
}

/**
 * 通用检查授权
 */
export async function auth() {
  if (cleanWxURLParams()) return
  let fullURL = window.location.href
  sessionStorage.setItem('auth_url', fullURL)
  if (fullURL.includes('?')) {
    fullURL = fullURL.split('?')[1].split('&')
    for (let i = 0; i < fullURL.length; i++) {
      const arr = fullURL[i].split('=')
      const _key = getStorageKey(arr[0])
      const _value = arr[1] + ''
      localStorage.setItem(_key, _value)
      if (arr[0] === 'token') token = _value
    }
  }
  let token = localStorage[storageKeys.token]
  if (!token) {
    // 先尝试从本地localStorage读取token
    token = localStorage.getItem(storageKeys.token)
  }
  if (!token) {
    // 参数无token尝试单点登录获取授权
    try {
      const authJsonStr = await msg2SingleAuth(
        JSON.stringify({
          type: 'getAuth',
          storageKeys: [storageKeys.token, storageKeys.uuid]
        })
      )
      const auth = JSON.parse(authJsonStr)
      token = auth[storageKeys.token]
      token && localStorage.setItem(storageKeys.token, token)
      const uuid = auth[storageKeys.uuid]
      uuid && localStorage.setItem(storageKeys.uuid, uuid)
      // eslint-disable-next-line no-empty
    } catch (error) {}
  } else {
    // 向单点登录写入授权信息
    msg2SingleAuth(
      JSON.stringify({
        type: 'setAuth',
        storageKvs: [
          {
            key: storageKeys.token,
            value: token
          },
          {
            key: storageKeys.uuid,
            value: localStorage.getItem(storageKeys.uuid)
          }
        ]
      })
    )
  }
  if (token) {
    // 去除授权参数
    setTimeout(() => {
      window.history.replaceState(null, null, cleanURLAuthParams())
    }, 0)
    return true
  } else {
    // 未授权
    gotoAuth(tokenAuthURL, 'source_url')
    return false
  }
}
