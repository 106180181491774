<template>
  <div class="my-videos-container">
    <div class="tab-bar">
      <div
        class="tab"
        :class="{ active: activeTab === 0 }"
        @click="switchTab(0)"
      >
        我的购买
      </div>
      <div class="seperator"></div>
      <div
        class="tab"
        :class="{ active: activeTab === 1 }"
        @click="switchTab(1)"
      >
        我的上传
      </div>
    </div>
    <div v-show="activeTab === 0">
      <van-list
        v-if="lists && lists.length"
        v-model="pagination.loading"
        :finished="pagination.finished"
        :error.sync="pagination.error"
        finished-text="没有更多了"
        error-text="加载失败，点击此处重新加载"
        @load="loadListNextPage"
      >
        <div
          id="masonry-wrap-0"
          class="img-link-wrap"
        >
          <a
            v-for="(v, vIndex) in lists"
            :key="v.uid"
            class="img-link"
            sensors-track="true"
            :sensors-data="
              JSONstr({
                $title: '我的视频',
                $element_content: '点击视频',
                video_title: v.title,
                video_id: v.uid
              })
            "
            @click="toVideoDetail(v, vIndex)"
          >
            <div class="bg-panel">
              <div
                class="img-aspectratio-wrap"
                aspectratio
                :style="{
                  paddingTop: v._aspectratioPaddingTop
                }"
              >
                <div
                  class="img"
                  aspectratio-content
                >
                  <van-image
                    class="post"
                    fit="cover"
                    lazy-load
                    :src="v.image_url"
                  >
                    <template v-slot:loading>
                      <van-loading
                        type="spinner"
                        size="30"
                      />
                    </template>
                  </van-image>
                </div>
              </div>
              <div class="desc">{{ v.title }}</div>
            </div>
          </a>
        </div>
      </van-list>
      <div
        v-if="lists && !lists.length"
        class="no-item"
      >
        <img src="../assets/img/no-item.png" />
        <p>您暂未购买任何视频</p>
      </div>
    </div>

    <div v-show="activeTab === 1">
      <van-list
        v-if="uploadList && uploadList.length"
        v-model="uploadPagination.loading"
        :finished="uploadPagination.finished"
        :error.sync="uploadPagination.error"
        finished-text="没有更多了"
        error-text="加载失败，点击此处重新加载"
        @load="loadUploadListNextPage"
      >
        <div
          id="masonry-wrap-1"
          class="img-link-wrap"
        >
          <a
            v-for="(v, vIndex) in uploadList"
            :key="v.uid"
            class="img-link"
            sensors-track="true"
            :sensors-data="
              JSONstr({
                $title: '我的视频',
                $element_content: '点击视频',
                video_title: v.title,
                video_id: v.uid
              })
            "
            @click="toVideoDetail(v, vIndex)"
          >
            <div class="bg-panel">
              <div
                class="img-aspectratio-wrap"
                aspectratio
                :style="{
                  paddingTop: v._aspectratioPaddingTop
                }"
              >
                <div
                  class="img"
                  aspectratio-content
                >
                  <van-image
                    v-if="v.image_url"
                    class="post"
                    fit="cover"
                    lazy-load
                    :src="v.image_url"
                  >
                    <template v-slot:loading>
                      <van-loading
                        type="spinner"
                        size="30"
                      />
                    </template>
                  </van-image>
                  <img
                    v-if="!v.image_url"
                    src="../assets/img/upload-video/logo.jpg"
                    class="post"
                  />
                </div>
              </div>
              <div class="desc">{{ v.title }}</div>

              <div
                v-if="v.check_status !== 2"
                class="upload-status-mask"
              >
                <img
                  v-if="v.check_status === 1"
                  src="../assets/img/upload-video/checking.png"
                />
                <img
                  v-if="v.check_status === 0"
                  src="../assets/img/upload-video/failed.png"
                />
              </div>
            </div>
          </a>
        </div>
      </van-list>
      <div
        v-if="uploadList && !uploadList.length"
        class="no-item"
      >
        <img src="../assets/img/no-item.png" />
        <p>您暂未上传任何视频</p>
      </div>
    </div>

    <a
      class="to-upload"
      @click="toUpload"
    >
      <img src="../assets/img/square-guide/to-upload.png" />
    </a>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, List, Lazyload, Image } from 'vant'
import Masonry from 'masonry-layout'
import { setWxShare } from '@/common/index'
import { storageKeys } from '@/env'

Vue.use(List)
  .use(Lazyload)
  .use(Image)

export default {
  name: 'MyVideos',
  data() {
    return {
      scroll: 0,
      lists: null,
      pagination: {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false
      },
      activeTab: 0,
      uploadList: null,
      uploadPagination: {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false
      }
    }
  },
  async activated() {
    if (sessionStorage.getItem(storageKeys.myVideosNeedRefresh)) {
      sessionStorage.removeItem(storageKeys.myVideosNeedRefresh)
      if (this.$masonry1) {
        this.$masonry1.destroy()
        this.$masonry1 = null
      }
      this.activeTab = 1
      this.uploadList = null
      this.uploadPagination = {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false
      }
      await this.loadUploadListNextPage()
      setTimeout(() => {
        window.scrollTo(0, this.activeTab === 1 ? 0 : this.scroll || 0)
      }, 60)
    } else {
      setTimeout(() => {
        window.scrollTo(0, this.scroll || 0)
      }, 60)
    }
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    document.title = '视频广场'
    this.setWx()
    const tab = this.$route.query.tab
    if (tab == 1) {
      this.activeTab = 1
      await this.loadUploadListNextPage()
    } else {
      await this.loadListNextPage()
    }
  },
  beforeDestroy() {
    if (this.$masonry0) {
      this.$masonry0.destroy()
      this.$masonry0 = null
    }
    if (this.$masonry1) {
      this.$masonry1.destroy()
      this.$masonry1 = null
    }
  },
  methods: {
    setWx() {
      setWxShare()
    },
    switchTab(idx) {
      if (idx !== this.activeTab) {
        this.activeTab = idx
        if (idx === 0) {
          if (!this.lists) {
            this.loadListNextPage()
          }
        }
        if (idx === 1) {
          if (!this.uploadList) {
            this.loadUploadListNextPage()
          }
        }
      }
    },
    async loadListNextPage() {
      const curPagination = this.pagination
      try {
        curPagination.loading = true
        const nextPage = curPagination.page + 1
        const res = await this.$axios.get('/video/v1/users/get_my_video', {
          params: {
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
        if (res.errCode === 0) {
          const appendList = res.data.videos
          appendList.forEach(t => {
            // 对样式进行预处理
            let _feJsonDataParsed = {
              coverAspectRatio: 1
            }
            t._feJsonDataParsed = _feJsonDataParsed
            t._aspectratioPaddingTop =
              (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
            try {
              _feJsonDataParsed = JSON.parse(t.fe_json_data)
              if (_feJsonDataParsed && _feJsonDataParsed.coverAspectRatio) {
                t._feJsonDataParsed = _feJsonDataParsed
                t._aspectratioPaddingTop =
                  (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
              }
              // eslint-disable-next-line no-empty
            } catch (error) { }
          })
          if (!this.lists) {
            this.lists = appendList
          } else {
            this.lists.push(...appendList)
          }
          curPagination.page = nextPage
          if (appendList.length < curPagination.pageSize) {
            curPagination.finished = true
          }
          curPagination.error = false
          await this.setWaterfall()
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
          curPagination.error = true
        }
        curPagination.loading = false
      } catch (error) {
        curPagination.loading = false
        curPagination.error = true
        console.error('拉取视频数据失败', error)
        Toast('拉取视频数据失败')
      }
    },

    async loadUploadListNextPage() {
      const curPagination = this.uploadPagination
      try {
        curPagination.loading = true
        const nextPage = curPagination.page + 1
        const res = await this.$axios.get('/video/v1/users/get_upload_video', {
          params: {
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
        if (res.errCode === 0) {
          const appendList = res.data.videos
          appendList.forEach(t => {
            // 对样式进行预处理
            let _feJsonDataParsed = {
              coverAspectRatio: 1
            }
            t._feJsonDataParsed = _feJsonDataParsed
            t._aspectratioPaddingTop =
              (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
            try {
              _feJsonDataParsed = JSON.parse(t.fe_json_data)
              if (_feJsonDataParsed && _feJsonDataParsed.coverAspectRatio) {
                t._feJsonDataParsed = _feJsonDataParsed
                t._aspectratioPaddingTop =
                  (100 / _feJsonDataParsed.coverAspectRatio).toFixed(2) + '%'
              }
              // eslint-disable-next-line no-empty
            } catch (error) { }
          })
          if (!this.uploadList) {
            this.uploadList = appendList
          } else {
            this.uploadList.push(...appendList)
          }
          curPagination.page = nextPage
          if (appendList.length < curPagination.pageSize) {
            curPagination.finished = true
          }
          curPagination.error = false
          await this.setWaterfall()
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
          curPagination.error = true
        }
        curPagination.loading = false
      } catch (error) {
        curPagination.loading = false
        curPagination.error = true
        console.error('拉取视频数据失败', error)
        Toast('拉取视频数据失败')
      }
    },
    async setWaterfall() {
      if (this.needSetWaterfall) return
      this.needSetWaterfall = true
      const activeTab = this.activeTab
      await this.$nextTick()
      await new Promise(resolve => {
        setTimeout(() => {
          if (activeTab === 0) {
            if (!this.$masonry0) {
              this.$masonry0 = new Masonry('#masonry-wrap-0', {
                transitionDuration: 0
              })
            } else {
              this.$masonry0.reloadItems()
              this.$masonry0.layout()
            }
          } else if (activeTab === 1) {
            if (!this.$masonry1) {
              this.$masonry1 = new Masonry('#masonry-wrap-1', {
                transitionDuration: 0
              })
            } else {
              this.$masonry1.reloadItems()
              this.$masonry1.layout()
            }
          }

          this.needSetWaterfall = false
          setTimeout(() => {
            resolve()
          }, 350)
        }, 150)
      })
    },
    async toVideoDetail(v, index) {
      // 初始化播放列表
      if (this.activeTab === 1) {
        if (v.check_status !== 2) return
        this.$store.commit('video/setActiveIndex', 0)
        this.$store.commit('video/setColumnId', '$myUploadVideos')
        this.$store.commit('video/setPlayList', JSON.parse(JSON.stringify([v])))
        this.$store.commit({
          page: 1,
          pageSize: 10,
          finished: true,
          loading: false,
          error: false
        })
      } else {
        this.$store.commit('video/setActiveIndex', index)
        this.$store.commit('video/setColumnId', '$myVideos')
        this.$store.commit(
          'video/setPlayList',
          JSON.parse(JSON.stringify(this.lists))
        )
        this.$store.commit(
          'video/setPlayPagination',
          JSON.parse(JSON.stringify(this.pagination))
        )
      }

      this.$store.commit('video/setUserWillingToPlay', true)
      this.$store.commit('video/setEntrance', 'wodeshipin')
      if (!this.$store.state.video.playedOnce) {
        await this.$store.dispatch('video/initPlay')
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 200)
        })
      } else {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 200)
        })
      }

      window.__videoSwiper.update()
      if (window.__videoSwiper.activeIndex === index) {
        this.$videoPlayerContainer.onSlideChangeEnd(index)
      } else {
        window.__noRequestVideoInfoInSlideChange = true
        window.__videoSwiper.slideTo(index, 0, true)
      }
      this.scroll = window.scrollY
      window.scrollTo(0, 0)
      this.$router.push({
        path: '/video',
        query: {
          uid: v.uid
        }
      })
    },
    toUpload() {
      this.$router.push('/upload-video?from=myvideos')
    }
  }
}
</script>

<style lang="less" scoped>
.my-videos-container {
  padding-bottom: calc(110px + constant(safe-area-inset-bottom));
  padding-bottom: calc(110px + env(safe-area-inset-bottom));
}

@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  40% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.img-link-wrap {
  width: 736px;
  margin: 7px auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .img-link {
    width: 367.5px;
    flex-basis: 367.5px;
    padding: 7px;
    padding-bottom: 14px;
    animation: enterAnimation 0.4s ease-in-out;
    .bg-panel {
      border-radius: 8px;
      overflow: hidden;
      background: #fff;
      position: relative;
      .desc {
        color: #29292f;
        font-size: 24px;
        line-height: 36px;
        padding: 14px 20px;
      }
      .img {
        .post {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.no-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 300px;
  > img {
    width: 464px;
  }
  > p {
    color: #29292f;
    font-size: 28px;
    font-weight: bold;
  }
}
.to-upload {
  position: fixed;
  right: 12px;
  bottom: 200px;
  img {
    width: 112px;
  }
}
</style>

<style lang="less" scoped>
.my-videos-container {
  padding-top: 90px;
}
.tab-bar {
  width: 750px;
  height: 90px;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .tab {
    flex: 1;
    height: 100%;
    font-size: 32px;
    color: #121212;
    font-weight: bold;
    text-align: center;
    line-height: 90px;
    position: relative;
    &.active {
      color: #00b7ae;
      &::after {
        content: '';
        width: 80px;
        height: 6px;
        border-radius: 4px;
        background-color: #00b7ae;
        position: absolute;
        bottom: 0;
        left: 148px;
      }
    }
  }
  .seperator {
    width: 2px;
    height: 48px;
    background-color: #dedede;
  }
}
.upload-status-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 148px;
    height: 148px;
  }
}
</style>

<style lang="less">
.my-videos-container {
  .van-image__error,
  .van-image__loading {
    height: 100%;
  }
  .van-image__error {
    position: relative;
  }
}
</style>
