import Vue from 'vue'
import { Toast } from 'vant'
import $axios from '@/services/axios'
import { tcAppID } from '@/env'

Vue.use(Toast)

const state = {
  showVideoPage: false,
  globalVideoPlayer: null,
  isPlaying: false,
  playedOnce: false,
  userWillingToPlay: false,
  activeIndex: 0,
  columnId: null,
  playList: null,
  playPagination: {},
  videoInfoMap: {},
  entrance: ''
}

const getters = {
  videoInfo: state => uid => {
    return state.videoInfoMap[uid]
  }
}

function promisifyEvent(listenerType, eventType) {
  return new Promise(resolve => {
    this[listenerType](eventType, () => {
      resolve('player-on: ' + eventType)
    })
  })
}

const actions = {
  initGlobalPlayer({ commit, state }, payload) {
    if (state.globalVideoPlayer) return
    const player = TCPlayer(payload.elementId, {
      appID: tcAppID,
      fileID: payload.vid,
      autoplay: false,
      preload: 'meta',
      loop: false,
      controls: false,
      bigPlayButton: false
    })
    player.$promisifyEvent = promisifyEvent.bind(player)
    commit('setGlobalVideoPlayer', player)
  },
  play({ commit, state }, toPlay) {
    const player = state.globalVideoPlayer
    if (!player) {
      commit('setIsPlaying', false)
      return
    }
    if (toPlay) {
      player.play()
      if (!player.paused()) {
        commit('setIsPlaying', true)
      }
    } else {
      player.pause()
      commit('setIsPlaying', false)
    }
  },
  async initPlay({ dispatch }) {
    dispatch('play', true)
    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 300)
    })
    dispatch('play', false)
  },
  async getVideoDetail({ commit, state, rootState }, payload) {
    try {
      const res = await $axios.get('/video/v1/column/video', {
        params: {
          uid: payload.uid,
          suuid: rootState.suuid,
          gzh: rootState.gzh,
          entrance: state.entrance
        }
      })
      if (res.errCode === 0) {
        const info = {
          ...res.data.card,
          ...res.data.video
        }
        commit('setVideoInfo', {
          uid: payload.uid,
          info
        })
        return info
      } else if (res.errCode === 2003) {
        return res.errCode
      } else if (res.errCode !== 1001) {
        Toast(res.errMsg)
      }
    } catch (error) {
      console.error('获取视频信息失败, uid=', payload.uid, error)
      Toast('拉取视频信息失败')
    }
  },
  async loadNextPage({ commit, state, rootState }) {
    const curPagination = state.playPagination
    if (curPagination.loading) return
    let updatedPagination = { ...curPagination }
    try {
      curPagination.loading = true
      commit('setPlayPagination', { ...curPagination, loading: true })
      const nextPage = curPagination.page + 1
      const columnId = state.columnId
      // 区分我的视频/视频广场
      let res
      if (columnId === '$recommendVideos') {
        res = await $axios.get('/video/v1/search/recommend', {
          params: {
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
      } else if (
        typeof columnId === 'string' &&
        columnId.startsWith('$searchVideos#')
      ) {
        const keyword = columnId.split('$searchVideos#')[1]
        res = await $axios.get('/video/v1/search/video', {
          params: {
            keyword,
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
      } else if (columnId === '$myVideos') {
        res = await $axios.get('/video/v1/users/get_my_video', {
          params: {
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
      } else if (columnId === '$shareRecommends' && rootState.suuid) {
        res = await $axios.get('/video/v1/column/get_next_video_list', {
          params: {
            excluded_uid: state.playList[0].uid,
            suuid: rootState.suuid,
            page: nextPage,
            page_size: curPagination.pageSize
          }
        })
      } else {
        res = await $axios.get('/video/v1/column/column_videos', {
          params: {
            column_id: columnId,
            page: nextPage,
            page_num: curPagination.pageSize
          }
        })
      }
      if (res.errCode === 0) {
        const appendList = res.data.videos
        commit('appendPlayList', appendList)
        updatedPagination.page = nextPage
        if (appendList.length === 0) {
          updatedPagination.finished = true
        }
        updatedPagination.error = false
      } else {
        console.error('拉取视频数据失败', res)
        updatedPagination.error = true
      }
      updatedPagination.loading = false
    } catch (error) {
      updatedPagination.loading = false
      updatedPagination.error = true
      console.error('拉取视频数据失败', error)
    }
    commit('setPlayPagination', updatedPagination)
  }
}

const mutations = {
  setShowVideoPage(state, bool) {
    state.showVideoPage = bool
  },
  setGlobalVideoPlayer(state, player) {
    state.globalVideoPlayer = player
  },
  setIsPlaying(state, bool) {
    state.isPlaying = bool
    if (!state.playedOnce && bool) state.playedOnce = true
  },
  setUserWillingToPlay(state, bool) {
    state.userWillingToPlay = bool
  },
  setPlayList(state, list) {
    state.playList = list
  },
  appendPlayList(state, appendList) {
    state.playList.push(...appendList)
  },
  setPlayPagination(state, pagination) {
    state.playPagination = pagination
  },
  setActiveIndex(state, index) {
    state.activeIndex = index
  },
  setVideoInfo(state, payload) {
    Vue.set(state.videoInfoMap, payload.uid, payload.info)
  },
  setColumnId(state, id) {
    state.columnId = id
  },
  setEntrance(state, entrance) {
    state.entrance = entrance
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
