var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-videos-container"},[_c('div',{staticClass:"tab-bar"},[_c('div',{staticClass:"tab",class:{ active: _vm.activeTab === 0 },on:{"click":function($event){return _vm.switchTab(0)}}},[_vm._v(" 我的购买 ")]),_c('div',{staticClass:"seperator"}),_c('div',{staticClass:"tab",class:{ active: _vm.activeTab === 1 },on:{"click":function($event){return _vm.switchTab(1)}}},[_vm._v(" 我的上传 ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 0),expression:"activeTab === 0"}]},[(_vm.lists && _vm.lists.length)?_c('van-list',{attrs:{"finished":_vm.pagination.finished,"error":_vm.pagination.error,"finished-text":"没有更多了","error-text":"加载失败，点击此处重新加载"},on:{"update:error":function($event){return _vm.$set(_vm.pagination, "error", $event)},"load":_vm.loadListNextPage},model:{value:(_vm.pagination.loading),callback:function ($$v) {_vm.$set(_vm.pagination, "loading", $$v)},expression:"pagination.loading"}},[_c('div',{staticClass:"img-link-wrap",attrs:{"id":"masonry-wrap-0"}},_vm._l((_vm.lists),function(v,vIndex){return _c('a',{key:v.uid,staticClass:"img-link",attrs:{"sensors-track":"true","sensors-data":_vm.JSONstr({
              $title: '我的视频',
              $element_content: '点击视频',
              video_title: v.title,
              video_id: v.uid
            })},on:{"click":function($event){return _vm.toVideoDetail(v, vIndex)}}},[_c('div',{staticClass:"bg-panel"},[_c('div',{staticClass:"img-aspectratio-wrap",style:({
                paddingTop: v._aspectratioPaddingTop
              }),attrs:{"aspectratio":""}},[_c('div',{staticClass:"img",attrs:{"aspectratio-content":""}},[_c('van-image',{staticClass:"post",attrs:{"fit":"cover","lazy-load":"","src":v.image_url},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"30"}})]},proxy:true}],null,true)})],1)]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(v.title))])])])}),0)]):_vm._e(),(_vm.lists && !_vm.lists.length)?_c('div',{staticClass:"no-item"},[_c('img',{attrs:{"src":require("../assets/img/no-item.png")}}),_c('p',[_vm._v("您暂未购买任何视频")])]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 1),expression:"activeTab === 1"}]},[(_vm.uploadList && _vm.uploadList.length)?_c('van-list',{attrs:{"finished":_vm.uploadPagination.finished,"error":_vm.uploadPagination.error,"finished-text":"没有更多了","error-text":"加载失败，点击此处重新加载"},on:{"update:error":function($event){return _vm.$set(_vm.uploadPagination, "error", $event)},"load":_vm.loadUploadListNextPage},model:{value:(_vm.uploadPagination.loading),callback:function ($$v) {_vm.$set(_vm.uploadPagination, "loading", $$v)},expression:"uploadPagination.loading"}},[_c('div',{staticClass:"img-link-wrap",attrs:{"id":"masonry-wrap-1"}},_vm._l((_vm.uploadList),function(v,vIndex){return _c('a',{key:v.uid,staticClass:"img-link",attrs:{"sensors-track":"true","sensors-data":_vm.JSONstr({
              $title: '我的视频',
              $element_content: '点击视频',
              video_title: v.title,
              video_id: v.uid
            })},on:{"click":function($event){return _vm.toVideoDetail(v, vIndex)}}},[_c('div',{staticClass:"bg-panel"},[_c('div',{staticClass:"img-aspectratio-wrap",style:({
                paddingTop: v._aspectratioPaddingTop
              }),attrs:{"aspectratio":""}},[_c('div',{staticClass:"img",attrs:{"aspectratio-content":""}},[(v.image_url)?_c('van-image',{staticClass:"post",attrs:{"fit":"cover","lazy-load":"","src":v.image_url},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"30"}})]},proxy:true}],null,true)}):_vm._e(),(!v.image_url)?_c('img',{staticClass:"post",attrs:{"src":require("../assets/img/upload-video/logo.jpg")}}):_vm._e()],1)]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(v.title))]),(v.check_status !== 2)?_c('div',{staticClass:"upload-status-mask"},[(v.check_status === 1)?_c('img',{attrs:{"src":require("../assets/img/upload-video/checking.png")}}):_vm._e(),(v.check_status === 0)?_c('img',{attrs:{"src":require("../assets/img/upload-video/failed.png")}}):_vm._e()]):_vm._e()])])}),0)]):_vm._e(),(_vm.uploadList && !_vm.uploadList.length)?_c('div',{staticClass:"no-item"},[_c('img',{attrs:{"src":require("../assets/img/no-item.png")}}),_c('p',[_vm._v("您暂未上传任何视频")])]):_vm._e()],1),_c('a',{staticClass:"to-upload",on:{"click":_vm.toUpload}},[_c('img',{attrs:{"src":require("../assets/img/square-guide/to-upload.png")}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }