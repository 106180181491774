var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"square-container",class:{ 'lock-scroll': _vm.showGuide }},[(_vm.showGuide)?_c('SquareGuide',{on:{"finish":_vm.onGuideFinish}}):_vm._e(),_c('div',{staticClass:"fixed-tabs"},[_c('van-tabs',{attrs:{"line-width":"20px","line-height":"2px","color":"#29292F","title-active-color":"#000","title-inactive-color":"#9B9B9B","swipe-threshold":5},on:{"change":_vm.onTabChange},model:{value:(_vm.activeTabIdx),callback:function ($$v) {_vm.activeTabIdx=$$v},expression:"activeTabIdx"}},_vm._l((_vm.tabs),function(tab){return _c('van-tab',{key:tab.column_id},[_c('div',{staticClass:"custom-tab",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(tab.column_name))]),(tab.hot)?_c('img',{staticClass:"hot-tab",attrs:{"src":require("../assets/img/hot.png")}}):_vm._e()])])}),1)],1),_c('div',{staticClass:"search-bar-wrap"},[_c('a',{staticClass:"search-bar",on:{"click":_vm.toSearch}},[_vm._v("点此搜索视频")])]),(_vm.lists && _vm.lists[_vm.activeTabIdx])?_c('van-list',{attrs:{"finished":_vm.pagination[_vm.activeTabIdx].finished,"error":_vm.pagination[_vm.activeTabIdx].error,"finished-text":"没有更多了","error-text":"加载失败，点击此处重新加载"},on:{"update:error":function($event){return _vm.$set(_vm.pagination[_vm.activeTabIdx], "error", $event)},"load":_vm.loadListNextPage},model:{value:(_vm.pagination[_vm.activeTabIdx].loading),callback:function ($$v) {_vm.$set(_vm.pagination[_vm.activeTabIdx], "loading", $$v)},expression:"pagination[activeTabIdx].loading"}},[_c('div',{staticClass:"img-link-wrap",attrs:{"id":"masonry-wrap"}},_vm._l((_vm.lists[_vm.activeTabIdx]),function(v,vIndex){return _c('a',{key:v.uid,staticClass:"img-link",attrs:{"sensors-track":"true","sensors-data":_vm.JSONstr({
            $title: '视频广场',
            $element_content: '点击视频',
            video_title: v.title,
            video_id: v.uid,
            column_name: _vm.activeTab.column_name,
            column_id: _vm.activeTab.column_id
          })},on:{"click":function($event){return _vm.toVideoDetail(v, vIndex)}}},[_c('div',{staticClass:"bg-panel"},[(v.is_top !== 1 && v._isNew)?_c('div',{staticClass:"is-new-tag"},[_vm._v(" 新上线 ")]):_vm._e(),_c('div',{staticClass:"img-aspectratio-wrap",style:({
              paddingTop: v._aspectratioPaddingTop
            }),attrs:{"aspectratio":""}},[_c('div',{staticClass:"img",attrs:{"aspectratio-content":""}},[_c('img',{staticClass:"play-icon",attrs:{"src":require("../assets/img/paused.png")}}),_c('van-image',{staticClass:"post",attrs:{"fit":"cover","lazy-load":"","src":v.image_url},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"30"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"hot-fire"},[_c('span',[_vm._v("播放量："+_vm._s(v._viewPvSimulate))]),(v.is_top === 1)?_c('img',{staticClass:"hot-fire-icon",attrs:{"src":require("../assets/img/hot-fire.png")}}):_vm._e()])],1)]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(v.title))])])])}),0)]):_vm._e(),_c('Footer',{attrs:{"active":0}}),_c('a',{staticClass:"to-upload",on:{"click":_vm.toUpload}},[_c('img',{attrs:{"src":require("../assets/img/square-guide/to-upload.png")}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }