<template>
  <div class="checked-corner">
    <div class="corner"></div>
    <img src="../assets/img/check.png" class="check-icon" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.checked-corner {
  position: relative;
  transform: scaleY(0.85);
  .corner {
    border-color: #1cb7a8 transparent transparent transparent;
    border-width: 50px;
    border-style: solid;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -50px;
    right: -50px;
  }
  .check-icon {
    position: absolute;
    width: 28px;
    height: 20px;
    bottom: 10px;
    right: 8px;
    transform: scaleY(1.18);
  }
}
</style>
