import Vue from 'vue'
import VueRouter from 'vue-router'
import sensors from '@/sensorsTrack'
import { txAnalysis } from '../txAnalysis'
import { formatDate } from '@/common/util'
import Square from '@/views/Square.vue'
import SquareWvm from '@/views/SquareWvm.vue'
import Auth from '@/views/auth.vue'
import MyVideos from '@/views/MyVideos.vue'
import Search from '@/views/Search.vue'
import Video from '@/views/Video.vue'
import Purchase from '@/views/Purchase.vue'
import Video404 from '@/views/Video404.vue'
import PurchaseSuccess from '@/views/PurchaseSuccess.vue'
import UploadVideo from '@/views/UploadVideo.vue'

import AdEdit from '@/views/adEdit/index.vue'
import adManage from '@/views/adManage/index.vue'
import UploadPhoto from '@/views/uploadPhoto/index.vue'

txAnalysis.init({
  sid: '500713071', //必填，统计用的appid
  cid: '500713072', //如果开启自定义事件，此项目为必填，否则不填
  autoReport: 0, //是否开aa启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
  senseHash: 1, //hash锚点是否进入url统计
  senseQuery: 0, //url参数是否进入url统计
  performanceMonitor: 0, //是否开启性能监控
  ignoreParams: [] //开启url参数上报时，可忽略部分参数拼接上报
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'square',
    component: Square,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/index-wvm',
    name: 'indexWvm',
    component: SquareWvm,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/my-videos',
    name: 'myVideos',
    component: MyVideos,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/upload-video',
    name: 'UploadVideo',
    component: UploadVideo
  },
  {
    path: '/video-404',
    name: 'Video404',
    component: Video404
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase
  },
  {
    path: '/purchase-success',
    name: 'PurchaseSuccess',
    component: PurchaseSuccess
  },
  {
    path: '/custom-ad-edit',
    name: 'CustomAdEdit',
    component: AdEdit
  },
  {
    path: '/ad-manage',
    name: 'AdManage',
    component: adManage
  },
  {
    path: '/upload-photo',
    name: 'UploadPhoto',
    component: UploadPhoto
  }
]

const router = new VueRouter({
  routes
})

router.afterEach(() => {
  // 神策分析：单页面中发送页面浏览事件
  Vue.nextTick(() => {
    const curr = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
    sensors.quick('autoTrackSinglePage', {
      event_time: curr
    })

    txAnalysis.pgv()
  })
})
export default router
