import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/services/auth'
import { wxPayCall } from '@/common'
import axiosInstance from '@/services/axios'
import { common } from '@topmdrt/common-sdk'
import topComponents from '@topmdrt/components'

Vue.use(topComponents)

const enforceDebug = window.location.href.includes('vconsole=1')

if (process.env.VUE_APP_DEPLOYMENT_ENV !== 'production' || enforceDebug) {
  common.initVConsole()
}

Vue.config.productionTip = false
Vue.prototype.$Auth = auth
Vue.prototype.$WxPayCall = wxPayCall
Vue.prototype.$axios = axiosInstance

Vue.mixin({
  methods: {
    JSONstr(obj) {
      return JSON.stringify(obj)
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
