<template>
  <div class="purchase-success-container">
    <div class="success-icon-wrap">
      <div class="bg">
        <van-icon name="success" class="v-success-icon" />
      </div>
      <p class="success-text">购买成功！</p>
    </div>
    <div class="margin"></div>
    <div class="p-info-wrap">
      <PurchaseCard
        :title="$route.query.title"
        :name="$route.query.name"
        :expire="$route.query.expire"
        class="full-card"
        :full="true"
      />
    </div>
    <div class="qr">
      <div class="qr-img">
        <img
          v-if="$route.query.type === 'video'"
          src="../assets/img/qr-video-min.png"
        />
        <img
          v-if="$route.query.type === 'vip'"
          src="../assets/img/qr-video-vip-min.png"
        />
      </div>
      <p class="qr-text">长按识别，获取视频</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import PurchaseCard from '@/components/PurchaseCard'

Vue.use(Icon)
export default {
  name: 'PurchaseSuccess',
  components: { PurchaseCard },
  data() {
    return {}
  },
  mounted() {
    document.title = '购买成功'
  }
}
</script>

<style lang="less" scoped>
.purchase-success-container {
  height: 100vh;
  background: #fff;
  .margin {
    height: 30px;
    background: #f4f6f7;
  }
  .success-icon-wrap {
    background: #fff;
    padding: 54px 0;
    .bg {
      background: #00b7ae;
      width: 118px;
      height: 118px;
      border-radius: 50%;
      color: #fff;
      margin-left: 316px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .v-success-icon {
        font-size: 80px;
        transform: translateY(6px);
      }
    }
    .success-text {
      color: #29292f;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      transform: translateX(8px);
    }
  }
  .p-info-wrap {
    margin-top: 30px;
    .full-card {
      width: 100%;
      border-radius: 0;
    }
  }
  .qr-img {
    width: 300px;
    height: 300px;
    margin-left: 225px;
    overflow: visible;
    > img {
      width: 300px;
      height: 300px;
      padding: 50px;
      box-sizing: content-box;
      position: relative;
      top: -50px;
      left: -50px;
    }
  }
  .qr-text {
    font-size: 28px;
    color: #676769;
    text-align: center;
  }
}
</style>

<style lang="less">
.purchase-success-container {
  .p-info-wrap .full-card {
    .title {
      padding: 0;
      margin: 0 24px;
    }
  }
}
</style>
