<template>
  <div class="purchase-container">
    <div class="broadcast">
      <van-swipe
        :autoplay="3000"
        :touchable="false"
        :show-indicators="false"
        vertical
        style="height: 100%;"
      >
        <van-swipe-item v-for="(t, index) in broadcastList" :key="index">
          {{ t }}
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="tabs-wrap">
      <!--      <div class="p-hr"></div>-->
      <van-tabs
        v-model="activeTabIdx"
        animated
        line-width="0"
        line-height="0"
        color="#29292F"
        :swipe-threshold="3"
      >
        <van-tab>
          <div slot="title" class="custom-tab">
            <div class="p-name">单品名片</div>
            <div class="price">
              <span class="unit">￥</span>
              <span class="real-price">
                {{ vProductInfo.final_price | price }}
              </span>
            </div>
            <p class="original-price">
              ￥{{ vProductInfo.original_price | price }}
            </p>
            <!--            <CheckedCorner v-show="activeTabIdx === 0" class="tab-corner" />-->
            <div v-if="inActivityTime" class="recommend-tag activity">
              限时一周
            </div>
          </div>
          <PurchaseCard
            title="单品商品"
            :type="0"
            :name="vProductInfo.product_name"
            expire="永久"
            remark="本服务开通之后，不可进行转让或退订"
            :link="detailLink"
          />
        </van-tab>
        <van-tab>
          <div slot="title" class="custom-tab">
            <div class="p-name">三个月会员</div>
            <div class="price">
              <span class="unit">￥</span>
              <span class="real-price">
                {{ mProductInfo2.final_price | price }}
              </span>
            </div>
            <p class="original-price">
              ￥{{ mProductInfo2.original_price | price }}
            </p>
            <!--            <CheckedCorner v-show="activeTabIdx === 1" class="tab-corner" />-->
          </div>
          <PurchaseCard
            title="三个月会员"
            :type="1"
            :name="mProductInfo2.product_name"
            expire="三个月（所有素材名片永久有效）"
            remark="本服务开通之后，不可进行转让或退订"
            :link="detailLink"
          />
        </van-tab>
        <van-tab>
          <div slot="title" class="custom-tab">
            <div class="p-name">会员包年</div>
            <div class="price">
              <span class="unit">￥</span>
              <span class="real-price">
                {{ mProductInfo.final_price | price }}
              </span>
            </div>
            <p class="original-price">
              ￥{{ mProductInfo.original_price | price }}
            </p>
            <!--            <CheckedCorner v-show="activeTabIdx === 2" class="tab-corner" />-->
            <div class="recommend-tag">推荐</div>
          </div>
          <PurchaseCard
            title="会员包年"
            :type="2"
            :name="mProductInfo.product_name"
            expire="一年（所有素材名片永久有效）"
            remark="本服务开通之后，不可进行转让或退订"
            :link="detailLink"
          />
        </van-tab>
        <van-tab>
          <div slot="title" class="custom-tab">
            <div class="p-name">会员两年</div>
            <div class="price">
              <span class="unit">￥</span>
              <span class="real-price">
                {{ mProductInfo3.final_price | price }}
              </span>
            </div>
            <p class="original-price">
              ￥{{ mProductInfo3.original_price | price }}
            </p>
            <!--            <CheckedCorner v-show="activeTabIdx === 2" class="tab-corner" />-->
            <!--            <div class="recommend-tag">推荐</div>-->
          </div>
          <PurchaseCard
            title="会员两年"
            :type="3"
            :name="mProductInfo3.product_name"
            expire="两年（所有素材名片永久有效）"
            remark="本服务开通之后，不可进行转让或退订"
            :link="detailLink"
          />
        </van-tab>
      </van-tabs>
      <VButton class="purchase-btn" @click="order()"
        >立即支付&nbsp;&nbsp;{{ payPrice | price }}&nbsp;&nbsp;元</VButton
      >
      <div class="agreement" @click="checked = !checked">
        <div
          class="check-agreement"
          :class="{
            checked
          }"
        >
          <img src="../assets/img/check.png" class="check-icon" />
        </div>
        <span>同意《</span>
        <span class="link" @click.stop="toAgreement">超级圆桌会员服务协议</span>
        <span>》</span>
      </div>
      <van-popup v-model="showProtocol" class="protocol-popup">
        <Protocol class="pop-protocol" />
        <PopupCloseIcon @click="showProtocol = false" />
      </van-popup>

      <van-popup v-model="showPurchaseVipPopup" class="purchase-vip-popup">
        <div class="purchase-vip-wrap">
          <a class="purchase-vip-btn" @click="order(2)">
            <span style="display: none;">开通包年</span>
            <img src="../assets/img/popup/purchase-vip-btn.png" />
          </a>
        </div>
        <PopupCloseIcon @click="showPurchaseVipPopup = false" />
      </van-popup>
    </div>
    <div class="vip-privilege">
      <p class="tit">超级VIP尊享8大权益</p>
      <div class="privilege-divs">
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-1.png"
          />
          <p class="p-1">谁查看我</p>
          <p class="p-2">挖掘更多潜在客户</p>
        </div>
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-2.png"
          />
          <p class="p-1">个人微站</p>
          <p class="p-2">展示品牌与专业度</p>
        </div>
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-3.png"
          />
          <p class="p-1">广告嵌入</p>
          <p class="p-2">每次分享都是机会</p>
        </div>
<!--        <div class="item">-->
<!--          <img-->
<!--            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-4.png"-->
<!--          />-->
<!--          <p class="p-1">赠险获客</p>-->
<!--          <p class="p-2">用免费小单换大单</p>-->
<!--        </div>-->
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-5.png"
          />
          <p class="p-1">营销海报</p>
          <p class="p-2">百万内容高效展业</p>
        </div>
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-6.png"
          />
          <p class="p-1">家庭测评</p>
          <p class="p-2">AI帮助客户分析</p>
        </div>
<!--        <div class="item">-->
<!--          <img-->
<!--            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-7.png"-->
<!--          />-->
<!--          <p class="p-1">邀请赚钱</p>-->
<!--          <p class="p-2">推广奖励高10倍</p>-->
<!--        </div>-->
        <div class="item">
          <img
            src="http://topmdrt-static.oss-cn-shenzhen.aliyuncs.com/wvm/member_rights/qy-icon-8.png"
          />
          <p class="p-1">展业指导</p>
          <p class="p-2">客服指导，社群交流</p>
        </div>
      </div>
    </div>
    <van-popup v-model="showVipStatus" class="vip-status-popup">
      <div>
        <p class="p-tit">温馨提示</p>
        <p class="p-tips">您的vip身份在以下公众号生效中</p>
        <p v-for="item in vipStatusList" class="p-text">
          {{ item.name }}：{{ item.expire_date }}到期
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Tab, Tabs, Toast, Swipe, SwipeItem, Popup } from 'vant'
import CheckedCorner from '@/components/CheckedCorner'
import PurchaseCard from '@/components/PurchaseCard'
import VButton from '@/components/VButton'
import PopupCloseIcon from '@/components/PopupCloseIcon'
import Protocol from '@/components/Protocol'
import { wxPayCall, setWxShare, inActivityTime } from '@/common/index'
import { vip_status } from '@/services/oldService'

const broadcastList = [
  '新华保险张*刚刚开通了包年会员',
  '中国人寿余*刚刚开通了包年会员',
  '琴*刚刚开通了包年会员',
  '李*华刚刚开通了包年会员',
  '阳光保险唐*权刚刚开通了包年会员',
  '万*明刚刚开通了包年会员',
  '陈*刚刚开通了包年会员',
  '中国平安李*明刚刚开通了包年会员',
  '富德生命人寿何*刚刚开通了包年会员',
  '中信保诚庄**刚刚开通了包年会员'
]
Vue.use(Tab)
  .use(Tabs)
  .use(Toast)
  .use(Swipe)
  .use(SwipeItem)
  .use(Popup)

export default {
  name: 'Purchase',
  components: {
    CheckedCorner,
    PurchaseCard,
    VButton,
    PopupCloseIcon,
    Protocol
  },
  filters: {
    price(n) {
      if (n === 0) return '0'
      if (!n) return '--'
      try {
        // return parseFloat(n).toFixed(2)
        return parseFloat(n)
      } catch (error) {
        return n
      }
    }
  },
  data() {
    return {
      activeTabIdx: 0,
      showVipStatus: false,
      vipStatusList: [],
      checked: true,
      vProductInfo: {
        product_name: '--'
      },
      // 一年
      mProductInfo: {
        product_name: '--'
      },
      // 三个月
      mProductInfo2: {
        product_name: '--'
      },
      // 两年
      mProductInfo3: {
        product_name: '--'
      },
      detailLink: 'http://wxurl.topbxr.cn/3fe6db',
      uid: '',
      vipPid: '26',
      broadcastList,
      showProtocol: false,
      inActivityTime: false,
      showPurchaseVipPopup: false
    }
  },
  computed: {
    ...mapState('video', ['videoInfoMap']),
    activeProductName() {
      if (this.activeTabIdx === 0) return this.vProductInfo.product_name
      if (this.activeTabIdx === 1) return this.mProductInfo2.product_name
      if (this.activeTabIdx === 2) return this.mProductInfo.product_name
      if (this.activeTabIdx === 3) return this.mProductInfo3.product_name
      return ''
    },
    payPrice() {
      if (this.activeTabIdx === 0) return this.vProductInfo.final_price
      if (this.activeTabIdx === 1) return this.mProductInfo2.final_price
      if (this.activeTabIdx === 2) return this.mProductInfo.final_price
      if (this.activeTabIdx === 3) return this.mProductInfo3.final_price
      return ''
    }
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    this.inActivityTime = inActivityTime(Date.now())
    document.title = '开通会员'
    const query = this.$route.query
    this.uid = query.uid
    this.getProductInfo()
    this.checkPurchaseStatus()
    vip_status({}).then(res => {
      if (res.success.length) {
        this.vipStatusList = res.success
        this.showVipStatus = true
      }
      console.log(res)
    })
    this.$sensorsTrack('EnterVIP', {
      entrance: 'fenxiao_shipinxiangqing',
      page_type: '视频购买页'
    })
    setWxShare()
  },
  methods: {
    checkPurchaseStatus() {
      this.$axios
        .get('/video/v1/column/check_is_buy_single')
        .then(res => {
          if (res.errCode === 0 && res.data.is_buy) {
            this.showPurchaseVipPopup = true
          }
        })
        .catch(() => {})
    },
    toAgreement() {
      this.showProtocol = true
    },
    getProductInfo() {
      this.$axios
        .get('/video/v1/order/get_video_price', {
          params: {
            suuid: this.$store.state.suuid,
            uid: this.uid
          }
        })
        .then(res => {
          if (res.errCode === 0) {
            res.data.products[0].original_price = 10
            this.vProductInfo = res.data.products[0]
          } else if (res.errCode !== 1001) {
            Toast(res.errMsg)
          }
        })
        .catch(error => {
          console.error(error)
          Toast('获取信息失败')
        })
      this.$axios
        .get('/video/v1/order/get_vip_price', {
          params: {
            suuid: this.$store.state.suuid,
            prod_ids: this.vipPid
          }
        })
        .then(res => {
          if (res.errCode === 0) {
            this.mProductInfo = res.data.products[0]
          } else if (res.errCode !== 1001) {
            Toast(res.errMsg)
          }
        })
        .catch(error => {
          console.error(error)
          Toast('获取信息失败')
        })
      this.$axios
        .get('/video/v1/order/get_vip_price', {
          params: {
            suuid: this.$store.state.suuid,
            prod_ids: '25'
          }
        })
        .then(res => {
          if (res.errCode === 0) {
            this.mProductInfo2 = res.data.products[0]
          } else if (res.errCode !== 1001) {
            Toast(res.errMsg)
          }
        })
        .catch(error => {
          console.error(error)
          Toast('获取信息失败')
        })
      this.$axios
        .get('/video/v1/order/get_vip_price', {
          params: {
            suuid: this.$store.state.suuid,
            prod_ids: '27'
          }
        })
        .then(res => {
          if (res.errCode === 0) {
            this.mProductInfo3 = res.data.products[0]
          } else if (res.errCode !== 1001) {
            Toast(res.errMsg)
          }
        })
        .catch(error => {
          console.error(error)
          Toast('获取信息失败')
        })
    },
    async order(idx) {
      if (!this.$store.state.video.playedOnce) {
        this.$store.dispatch('video/play', true)
        setTimeout(() => {
          this.$store.dispatch('video/play', false)
        }, 100)
      }
      let orderURL = ''
      let prodId = null
      let type = ''
      let idKey = ''

      const selectedIdx = typeof idx === 'number' ? idx : this.activeTabIdx
      if (selectedIdx === 0) {
        orderURL = '/video/v1/order/video_order'
        prodId = this.uid
        type = 'video'
        idKey = 'uid'
      }
      if (selectedIdx === 1) {
        orderURL = '/video/v1/order/order'
        prodId = '25'
        type = 'vip'
        idKey = 'prod_id'
      }
      if (selectedIdx === 2) {
        orderURL = '/video/v1/order/order'
        prodId = this.vipPid
        type = 'vip'
        idKey = 'prod_id'
      }
      if (selectedIdx === 3) {
        orderURL = '/video/v1/order/order'
        prodId = '27'
        type = 'vip'
        idKey = 'prod_id'
      }
      this.$sensorsTrack('ClickVIP', {
        entrance: 'fenxiao_shipinxiangqing',
        page_type: '视频购买页',
        product_id: prodId + '',
        product_name: this.activeProductName
      })
      if (!this.checked) {
        Toast('您需要同意《超级圆桌会员服务协议》才可继续操作')
        return
      }
      try {
        const res = await this.$axios.post(orderURL, {
          [idKey]: prodId,
          suuid: this.$store.state.suuid
        })
        if (res.errCode === 0) {
          const params = res.data.api_js_params
          wxPayCall({
            params,
            success: () => {
              // 检查是否公众号
              this.purchaseSuccess(type)
            },
            fail: () => {
              Toast('支付失败')
            },
            cancel: () => {}
          })
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
        }
      } catch (error) {
        Toast('下单失败')
        console.error('购买失败', error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async purchaseSuccess(type) {
      this.$store.commit('setSuuid', '')

      this.$store.commit('video/setUserWillingToPlay', true)
      if (window.__videoSwiper.activeIndex) {
        this.$videoPlayerContainer.onSlideChangeEnd(
          window.__videoSwiper.activeIndex
        )
      }
      this.$router.replace({
        path: '/video',
        query: {
          uid: this.uid
        }
      })

      // const gzhMap = {
      //   video: 'video',
      //   vip: 'vip_video'
      // }
      // const toSuccess = () => {
      //   /*
      //   关注圆桌保视：http://wxurl.topbxr.cn/3fe6f9
      //   关注圆桌保视VIP：http://wxurl.topbxr.cn/3fe6fa
      //   */
      //   if (type === 'video') {
      //     window.location.href = `http://wxurl.topbxr.cn/3fe6f9?official_open_id=${
      //       localStorage[storageKeys.official_open_id]
      //     }`
      //   }
      //   if (type === 'vip') {
      //     window.location.href = `http://wxurl.topbxr.cn/3fe6fa?official_open_id=${
      //       localStorage[storageKeys.official_open_id]
      //     }`
      //   }
      // }
      // try {
      //   const res = await this.$axios.post('/video/v1/users/check_subscribe', {
      //     gzh: gzhMap[type]
      //   })
      //   if (res.errCode === 0) {
      //     const isSubscribe = res.data.subscribe
      //     if (isSubscribe === 1) {
      //       this.$store.commit('video/setUserWillingToPlay', true)
      //       if (window.__videoSwiper.activeIndex) {
      //         this.$videoPlayerContainer.onSlideChangeEnd(
      //           window.__videoSwiper.activeIndex
      //         )
      //       }
      //       this.$router.replace({
      //         path: '/video',
      //         query: {
      //           uid: this.uid
      //         }
      //       })
      //     } else {
      //       toSuccess()
      //     }
      //   } else {
      //     toSuccess()
      //   }
      // } catch (error) {
      //   toSuccess()
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-container {
  background: #fff;
  min-height: 100vh;
  .vip-privilege {
    padding: 46px 24px;
    background: #fff;
    .tit {
      color: #333333;
      font-size: 36px;
    }
    .privilege-divs {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 220px;
        height: 274px;
        background: #ffffff;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        margin: 16px 7px;
        padding-top: 50px;
        img {
          display: block;
          margin: 0 auto;
          width: 104px;
          height: 104px;
        }
        p {
          text-align: center;
        }
        .p-1 {
          color: #333333;
          font-size: 28px;
          line-height: 62px;
        }
        .p-2 {
          color: #666666;
          font-size: 22px;
        }
      }
    }
  }
  .broadcast {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 28px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .custom-tab {
    width: 184px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #f4f6f7;
    margin: 0 10px;
    border: 4px solid #f4f6f7;
    .p-name {
      color: #676769;
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
    }
    .price,
    .original-price {
      font-family: Helvetica;
    }
    .price {
      display: flex;
      align-items: flex-end;
      transform: translateX(-6px);
      > span {
        line-height: 1;
        color: #cb8723;
      }
      .unit {
        font-size: 36px;
        transform: translateY(-4px);
        font-weight: normal;
      }
      .real-price {
        line-height: 76px;
        font-size: 56px;
        font-weight: bold;
      }
    }
    .original-price {
      color: #a7a7a7;
      text-decoration: line-through;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      margin-top: 8px;
    }
    .recommend-tag {
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      background: #ff6155;
      border-radius: 0 10px 0 10px;
      position: absolute;
      top: -4px;
      left: 11px;
      font-weight: bold;
      font-size: 24px;
      &.activity {
        width: 130px;
      }
    }
  }
  .tab-corner {
    position: absolute;
    bottom: 4px;
    right: 14px;
  }
  .tabs-wrap {
    position: relative;
    .p-hr {
      position: absolute;
      z-index: 101;
      top: 312px;
      left: 0;
      height: 4px;
      width: 750px;
      background-image: url(../assets/img/purchase-hr.png);
      background-size: 750px 4px;
    }
  }
  .purchase-btn {
    font-size: 32px;
    width: 702px;
    margin: 8px 24px 0;
  }
  .agreement {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #676769;
    height: 70px;
    margin-top: 14px;
    width: 450px;
    margin-left: 150px;
    .link {
      color: #1cb7a8;
      padding: 18px 0;
    }
    .check-agreement {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #bbb;
      border-radius: 50%;
      margin-right: 8px;
      > img {
        width: 16px;
      }
    }
    .checked {
      background: #00b7ae;
    }
  }
}
.protocol-popup {
  width: 600px;
  background: none !important;
  .pop-protocol {
    height: 70vh;
    overflow-y: scroll;
    background: #fff;
    border-radius: 10px;
  }
}

.purchase-vip-popup {
  background: none !important;
  .purchase-vip-wrap {
    width: 454px;
    height: 528px;
    background-image: url(../assets/img/popup/purchase-vip-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .purchase-vip-btn {
    width: 400px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 32px;
    left: 27px;
    img {
      width: 306px;
      height: 89px;
    }
  }
}
</style>

<style lang="less">
.vip-status-popup {
  padding: 30px;
  width: 680px;
  color: #333333;
  border-radius: 10px;
  .p-tit {
    text-align: center;
    line-height: 40px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .p-tips {
    line-height: 40px;
    margin-bottom: 10px;
  }
  .p-text {
    margin-bottom: 10px;
  }
}
.purchase-container {
  .van-swipe-item {
    background: linear-gradient(130deg, #ffefdd 0%, #ffe2c0 69%, #ffe2c0 100%);
    color: #ab8948;
  }
  .van-tab {
    height: 250px;
    line-height: initial;
    padding: 0;
  }
  .van-tabs__wrap {
    height: 314px;
    line-height: initial;
    padding: 34px 14px;
    background: #fff;
    position: relative;
  }
  .van-tab__text {
    width: 100%;
  }
  .van-tab--active {
    .custom-tab {
      background: #fff5de;
      border: 4px solid #ebbf66;
    }
  }
  .van-tab__pane {
    padding: 30px 24px;
  }
}
</style>
