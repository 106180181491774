<template>
  <div class="search-container">
    <div class="search-bar">
      <Input
        ref="searchInput"
        v-model="searchStr"
        class="search-input"
        placeholder="输入“社保”试试"
      />
      <img
        v-show="searchStr"
        src="../assets/img/search/x-circle.png"
        class="search-cancel"
        @click="searchStr = ''"
      />
      <a class="search-btn" @click="doSearch">搜索</a>
    </div>
    <van-list
      v-if="!searchNoResult && lists && lists.length"
      v-model="pagination.loading"
      class="list-wrap"
      :finished="pagination.finished"
      :error.sync="pagination.error"
      finished-text="没有更多了"
      error-text="加载失败，点击此处重新加载"
      @load="loadListNextPage"
    >
      <div
        v-for="(item, index) in lists"
        :key="item.uid"
        class="v-item"
        @click="toVideoDetail(item, index)"
      >
        <div class="item-left">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-pv">播放量：{{ item._viewPvSimulate }}</div>
        </div>
        <van-image
          class="item-cover"
          fit="cover"
          lazy-load
          :src="item.image_url"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="30" />
          </template>
        </van-image>
      </div>
    </van-list>
    <div v-if="searchNoResult" class="no-item">未搜索到任何相关内容</div>
    <div
      v-if="searchNoResult && lists && lists.length"
      class="recommend-list-title"
    >
      以下视频获客效果都不错哦！
    </div>
    <van-list
      v-if="searchNoResult && lists && lists.length"
      v-model="pagination.loading"
      class="list-wrap"
      :finished="pagination.finished"
      :error.sync="pagination.error"
      finished-text="没有更多了"
      error-text="加载失败，点击此处重新加载"
      @load="loadListNextPage"
    >
      <div
        v-for="(item, index) in lists"
        :key="item.uid"
        class="v-item"
        @click="item, index"
      >
        <div class="item-left">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-pv">播放量：{{ item._viewPvSimulate }}</div>
        </div>
        <van-image
          class="item-cover"
          fit="cover"
          lazy-load
          :src="item.image_url"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="30" />
          </template>
        </van-image>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, List, Image, Lazyload } from 'vant'
import { setWxShare } from '@/common/index'
import Input from '@/components/Input.vue'
import { videoPublicURL } from '@/env'

Vue.use(List)
  .use(Image)
  .use(Lazyload)

export default {
  name: 'Search',
  components: { Input },
  data() {
    return {
      searchStr: '',
      searchNoResult: false,
      lists: null,
      pagination: {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false,
        scroll: 0
      }
    }
  },
  activated() {
    setTimeout(() => {
      window.scrollTo(0, this.pagination.scroll || 0)
    }, 60)
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    document.title = '视频广场'
    this.setWx()
    await this.$nextTick()
    // this.$refs.searchInput.$refs.inputRef.focus()
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/video') {
      this.cancelSearch()
    }
    next()
  },
  methods: {
    setWx() {
      setWxShare()
    },
    doSearch() {
      const keyword = this.searchStr.trim()
      if (keyword) {
        if (keyword === this.lastKeyword) return
        this.lastKeyword = keyword
        this.cancelSearch()
        this.searchStr = keyword
        this.loadListNextPage(keyword)
      } else {
        Toast('请输入搜索内容')
      }
    },
    cancelSearch() {
      this.searchStr = ''
      this.searchNoResult = false
      this.lists = null
      this.pagination = {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false,
        scroll: 0
      }
    },
    async loadListNextPage(keyword) {
      const curPagination = this.pagination
      const url = this.searchNoResult
        ? '/video/v1/search/recommend'
        : '/video/v1/search/video'
      try {
        curPagination.loading = true
        const nextPage = curPagination.page + 1
        const params = {
          page: nextPage,
          page_num: curPagination.pageSize
        }
        if (url === '/video/v1/search/video')
          params.keyword = keyword || this.lastKeyword
        const res = await this.$axios.get(url, {
          params
        })
        if (res.errCode === 0) {
          const appendList = res.data.videos
          appendList.forEach(t => {
            try {
              // 阅读数处理
              t._viewPvSimulate = t.base_view_num * 37 + 10000
              // eslint-disable-next-line no-empty
            } catch (error) {}
          })
          // 如果没有搜索结果
          if (appendList.length === 0 && nextPage === 1) {
            this.searchNoResult = true
            this.loadListNextPage()
            return
          }
          if (!this.lists) {
            this.lists = appendList
          } else {
            this.lists.push(...appendList)
          }
          curPagination.page = nextPage
          if (appendList.length < curPagination.pageSize) {
            curPagination.finished = true
          }
          curPagination.error = false
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
          curPagination.error = true
        }
        curPagination.loading = false
      } catch (error) {
        curPagination.loading = false
        curPagination.error = true
        console.error('拉取视频数据失败', error)
        Toast('拉取视频数据失败')
      }
    },
    async toVideoDetail(v, index) {
      // 初始化播放列表
      this.$store.commit('video/setActiveIndex', index)
      this.$store.commit(
        'video/setColumnId',
        this.searchNoResult
          ? '$recommendVideos'
          : `$searchVideos#${this.searchStr.trim()}`
      )
      this.$store.commit(
        'video/setPlayList',
        JSON.parse(JSON.stringify(this.lists))
      )
      this.$store.commit(
        'video/setPlayPagination',
        JSON.parse(JSON.stringify(this.pagination))
      )
      this.$store.commit('video/setUserWillingToPlay', true)
      this.$store.commit('video/setEntrance', 'wodeshipin')
      if (!this.$store.state.video.playedOnce) {
        await this.$store.dispatch('video/initPlay')
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 200)
        })
      } else {
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 200)
        })
      }
      window.__videoSwiper.update()
      if (window.__videoSwiper.activeIndex === index) {
        this.$videoPlayerContainer.onSlideChangeEnd(index)
      } else {
        window.__noRequestVideoInfoInSlideChange = true
        window.__videoSwiper.slideTo(index, 0, true)
      }
      this.pagination.scroll = window.scrollY
      window.scrollTo(0, 0)
      if (this.$route.query.source == 'wvm') {
        const messageStr = {
          type: 'url',
          value: `${videoPublicURL}video?uid=${v.uid}`
        }
        window.parent.postMessage(JSON.stringify(messageStr), '*')
      } else {
        this.$router.push({
          path: '/video',
          query: {
            uid: v.uid
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-container {
  padding-top: 86px;
  .search-bar {
    width: 750px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #f4f6f7;
    z-index: 1;
  }
  .search-input {
    width: 618px;
    height: 64px;
    border-radius: 32px;
    background: rgba(232, 232, 233, 1);
    border: none;
    padding: 0 60px 0 30px;
    font-size: 28px;
    line-height: normal;
  }
  .search-btn {
    color: #00b7ae;
    font-size: 28px;
    padding: 0 15px 0 20px;
  }
  .search-cancel {
    width: 28px;
    height: 28px;
    box-sizing: content-box;
    padding: 20px;
    position: absolute;
    top: 10px;
    right: 112px;
    z-index: 1;
  }
  .list-wrap {
    background-color: #fff;
    padding: 0 32px;
  }
  .v-item {
    display: flex;
    height: 224px;
    padding: 30px 0;
    & + .v-item {
      border-top: 2px solid #efeff0;
    }
    .item-left {
      flex-basis: 472px;
      width: 472px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item-title {
        font-size: 28px;
        font-weight: bold;
      }
      .item-pv {
        font-size: 26px;
        color: #a7a7a7;
      }
    }
    .item-cover {
      flex-basis: 214px;
      width: 214px;
      height: 160px;
    }
  }
  .no-item {
    height: 104px;
    font-size: 28px;
    color: #a7a7a7;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    background-color: #fff;
  }
  .recommend-list-title {
    color: #676769;
    font-size: 28px;
    height: 60px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
  }
}
</style>
