<template>
  <input
    ref="inputRef"
    :value="value"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @compositionstart="onCompositionstart"
    @compositionend="onCompositionend"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
  />
</template>

<script>
import { adjustInputPosition, resolveIOSInputBlurBug } from '@/common'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    },
    filter: {
      type: Function,
      default(value) {
        return value
      }
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(evt) {
      if (this.inComposition) return
      if (!this.valueChanged) this.valueChanged = true
      this.emitInput(evt)
    },
    onCompositionstart() {
      this.inComposition = true
    },
    onCompositionend(evt) {
      this.inComposition = false
      this.emitInput(evt)
    },
    emitInput(evt) {
      const oldValue = this.value
      let filterValue = this.filter(evt.target.value)
      if (
        this.valueChanged &&
        this.maxLength > 0 &&
        filterValue.length > this.maxLength
      ) {
        filterValue = filterValue.substring(0, this.maxLength)
        this.$emit('overMaxLength')
      }
      evt.target.value = filterValue
      if (filterValue !== oldValue) this.$emit('input', filterValue)
    },
    onFocus(evt) {
      if (this.fixed) {
        adjustInputPosition()
      }
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      resolveIOSInputBlurBug()
      this.$emit('blur', evt)
    },
    onTouchStart(e) {
      this.lastScrollY = e.changedTouches[0].clientY
    },
    onTouchMove(e) {
      const element = this.$refs.inputRef
      const maxScrollY = element.scrollHeight - element.offsetHeight
      const toY = e.changedTouches[0].clientY
      e.stopPropagation()
      if (toY >= this.lastScrollY && element.scrollTop <= 0) {
        this.lastScrollY = toY
        e.preventDefault()
      } else if (toY <= this.lastScrollY && element.scrollTop >= maxScrollY) {
        this.lastScrollY = toY
        e.preventDefault()
      }
    }
  }
}
</script>
