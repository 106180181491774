<template>
  <div class="p-card">
<!--    <div class="title">-->
<!--      <span class="dot"></span>-->
<!--      <span class="title-text">{{ title }}</span>-->
<!--    </div>-->
    <div class="card-details">
      <van-row type="flex" class="detail-row">
        <van-col span="6" class="label">
          <span>商</span>
          <span>品</span>
          <span>名</span>
          <span>称：</span>
        </van-col>
        <van-col span="18" class="value">{{ name }}</van-col>
      </van-row>
      <van-row type="flex" class="detail-row">
        <van-col span="6" class="label">
          <span>有</span>
          <span>效</span>
          <span>期：</span>
        </van-col>
        <van-col span="18" class="value expire">{{ expire }}</van-col>
      </van-row>
      <van-row v-if="!full" type="flex" class="detail-row">
        <van-col span="6" class="label">
          <span>提</span>
          <span>醒</span>
          <span>事</span>
          <span>项：</span>
        </van-col>
        <van-col span="18" class="value">{{ remark }}</van-col>
      </van-row>
      <van-row v-if="!full" type="flex" class="detail-row">
        <p v-if="type == 0" class="detail-tips">开通VIP，全部视频免费用，1年预计<span>能省1600元</span></p>
        <p v-if="type == 1" class="detail-tips">开通VIP，1年预计<span>能省1600元</span></p>
        <p v-if="type == 2" class="detail-tips">开通VIP，2年预计<span>能省3200元</span></p>
        <p v-if="type == 3" class="detail-tips">开通VIP，1年预计<span>能省1600元</span></p>
<!--        <van-col span="6" class="label">-->
<!--          <span>介</span>-->
<!--          <span>绍：</span>-->
<!--        </van-col>-->
<!--        <van-col-->
<!--          span="18"-->
<!--          class="value"-->
<!--          :class="{ link }"-->
<!--          @click="onClickLink($event)"-->
<!--          >{{ link ? '查看详情' : '&#45;&#45;' }}</van-col-->
<!--        >-->
      </van-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { storageKeys } from '@/env'
import { Row, Col } from 'vant'
Vue.use(Row).use(Col)

export default {
  props: ['title', 'name', 'expire', 'remark', 'link', 'full','type'],
  methods: {
    onClickLink(event) {
      this.$sensors.quick('trackAllHeatMap', event.target, {
        $element_content: '查看详情'
      })
      setTimeout(() => {
        window.location.href = this.link + '?=official_open_id=' + localStorage.getItem(storageKeys.official_open_id)
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.p-card {
  background: #F7F7F7;
  border-radius: 8px;
  width: 702px;
  .title {
    height: 108px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border-bottom: 2px solid #efeff0;
    .dot {
      width: 8px;
      height: 28px;
      background: #00b7ae;
      border-radius: 4px;
    }
    .title-text {
      font-size: 32px;
      line-height: 44px;
      font-weight: bold;
      color: #333;
      margin-left: 8px;
    }
  }
  .card-details {
    padding: 32px 24px 44px;
    .detail-row {
      height: 64px;
      line-height: 44px;
      margin-bottom: 40px;
      .detail-tips{
        text-align: center;
        width: 100%;
        color: #333333;
        font-size: 30px;
        span{
          color: #FF500F;
          font-size: 30px;
        }
      }
    }
    .label {
      color: #676769;
      font-size: 28px;
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
    }
    .value {
      color: #29292f;
      font-size: 28px;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #DADADA;
    }
    .expire {
      color: #333333;
    }
    .link {
      color: #1cb7a8;
      text-decoration: underline;
    }
  }
}
</style>
