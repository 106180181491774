<template>
  <div class="ad-edit-container">
    <ad-edit-form
      v-if="form"
      ref="refForm"
      :init-form="form"
      :ad-image="form.image"
      @submit="onSubmit"
      @setImage="setImage"
      @clearImage="form.image = ''"
    />
  </div>
</template>

<script>
import axiosInstance from '@/services/axios'

export default {
  data() {
    return {
      form: null
    }
  },
  async mounted() {
    if (this.$root._cache && this.$root._cache.adEditData) {
      this.form = { ...this.$root._cache.adEditData.form }
      delete this.$root._cache.adEditData
    } else {
      const id = this.$route.query.id
      if (id) {
        const res = await this.requestAdData(id)
        if (res.errCode === 0) {
          this.form = res.data.datas
        } else {
          this.form = {}
        }
      } else {
        this.form = {}
      }
    }
  },
  methods: {
    requestAdData(id) {
      return axiosInstance.get('/video/v1/custom_adv/get_one_custom_adv', {
        params: {
          advert_id: id
        }
      })
    },
    setImage() {
      if (!this.$root._cache) this.$root._cache = {}
      this.$root._cache.adEditData = {
        form: this.$refs.refForm.form
      }
      this.$root._cache.adEditData.form.image = this.form.image
      this.$root._cache.oncePhotoclipSuccess = image => {
        this.$root._cache.adEditData.form.image = image

        delete this.$root._cache.oncePhotoclipSuccess
      }
      this.$router.push('/upload-photo')
    },
    onSubmit({ form }) {
      const params = { ...form }
      if (params.id) {
        params.adv_id = params.id
        delete params.id
      }
      axiosInstance
        .post('/video/v1/custom_adv/add_one', { ...params })
        .then(data => {
          if (data.errCode === 0) {
            this.$toast('保存成功')
            if (this.$root._cache && this.$root._cache.onceCustomAdEditBack) {
              setTimeout(this.$root._cache.onceCustomAdEditBack)
            }
            this.$router.go(-1)
            if (!params.adv_id) {
              this.$sensorsTrack('NewAd', {
                entrance: '视频'
              })
            }
          } else {
            this.$toast(data.errMsg)
          }
        })
        .catch(() => {
          this.$toast('保存失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.ad-edit-container {
  height: 100vh;
  overflow-y: scroll;
}
</style>
