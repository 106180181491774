import $axios from '@/services/axios'
import store from '@/store'
import { ENV, tcAppID } from '@/env'

export function inActivityTime(datetime) {
  // 限时一周单品降价
  // 【ID1004895】11-25~12-1单品名片全部改为1元，12-2变更回5元
  const start = new Date(2019, 10, 25)
  const end = new Date(2019, 11, 2)
  return datetime >= start && datetime < end
}

let timer
export function adjustInputPosition() {
  typeof timer !== 'undefined' && clearTimeout(timer)
  // 解决移动端fixed定位下软键盘弹出影响输入框定位的问题
  if (navigator.userAgent.includes('iPhone')) {
    timer = setTimeout(function() {
      // eslint-disable-next-line no-self-assign
      document.body.scrollTop = document.body.scrollTop
    }, 700)
  } else {
    timer = setTimeout(function() {
      document.body.scrollTop = document.body.scrollHeight
    }, 700)
  }
}

// 解决微信ios输入失焦bug
export function resolveIOSInputBlurBug() {
  setTimeout(function() {
    if (
      // eslint-disable-next-line eqeqeq
      document.activeElement.tagName == 'INPUT' ||
      // eslint-disable-next-line eqeqeq
      document.activeElement.tagName == 'TEXTAREA'
    ) {
      return
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // 判断iPhone|iPad|iPod|iOS
      document.activeElement.scrollIntoViewIfNeeded(true)
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // 判断Android
    } else {
      // PC
    }
  }, 10)
}

/**
 * 调起前端微信支付
 */
export function wxPayCall(options = {}) {
  const doCall = () => {
    WeixinJSBridge.invoke('getBrandWCPayRequest', options.params, res => {
      if (res.err_msg === 'get_brand_wcpay_request:ok') {
        if (options.success) options.success()
      } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
        if (options.fail) options.fail()
      } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
        if (options.cancel) options.cancel()
      }
    })
  }
  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', doCall, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', doCall)
      document.attachEvent('onWeixinJSBridgeReady', doCall)
    }
  } else {
    doCall()
  }
}

/**
 * 微信浏览器下自动播放视频
 */
export function wxAutoPlayVideo(el) {
  return new Promise(resolve => {
    function autoPlay() {
      WeixinJSBridge.invoke(
        'getNetworkType',
        {},
        function() {
          el.play()
          resolve()
        },
        false
      )
    }
    if (window.WeixinJSBridge) {
      autoPlay()
    } else {
      document.addEventListener('WeixinJSBridgeReady', autoPlay, false)
    }
  })
}

/**
 * 微信初始化
 */
export const setWxConfig = async () => {
  // 初始化 wxconfig
  const res = await $axios.get('/video/v1/share/get_sign_package', {
    params: {
      url: window.location.href.split('#')[0],
      gzh: store.state.gzh
    }
  })

  function onProdWxConfigError(params, error) {
    console.log('wx.config失败 params:', params, 'res:', res)
    if (ENV !== 'prod') return
    window.sensorsTrack('WxConfigError', {
      app: '圆桌保视',
      params: JSON.stringify(params),
      error: JSON.stringify(error)
    })
  }

  if (res.errCode === 0) {
    const params = JSON.parse(res.data.params)
    params.jsApiList = [
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'showMenuItems',
      'hideMenuItems'
    ]
    // params.debug = true
    wx.config(params)

    wx.error(function(res) {
      onProdWxConfigError(params, res)
    })
  } else {
    onProdWxConfigError(null, res)
  }
}

export function setWxShare(config) {
  wx.ready(() => {
    if (!config) {
      wx.hideMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:copyUrl',
          'menuItem:originPage',
          'menuItem:openWithQQBrowser',
          'menuItem:openWithSafari',
          'menuItem:share:email'
        ]
      })
      return
    }
    // if (wx.checkJsApi) {
    //   wx.checkJsApi({
    //     jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
    //     success: res => {
    //       const { checkResult } = res
    //       if (
    //         checkResult &&
    //         checkResult.updateAppMessageShareData &&
    //         checkResult.updateTimelineShareData
    //       ) {
    //         wx.updateAppMessageShareData(config)
    //         wx.updateTimelineShareData(config)
    //         return
    //       }
    //       wx.onMenuShareAppMessage(config)
    //       wx.onMenuShareTimeline(config)
    //     }
    //   })
    // } else {
    //   wx.onMenuShareAppMessage(config)
    //   wx.onMenuShareTimeline(config)
    // }
    wx.onMenuShareAppMessage(config)
    wx.onMenuShareTimeline(config)

    wx.hideMenuItems({
      menuList: [
        'menuItem:copyUrl',
        'menuItem:originPage',
        'menuItem:openWithQQBrowser',
        'menuItem:openWithSafari',
        'menuItem:share:email'
      ]
    })
    wx.showMenuItems({
      menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
    })
  })
}

export function initTCPlayer(vid, elementId) {
  const el = document.getElementById(elementId)
  const elTag = el.tagName.toLowerCase()
  if (elTag !== 'video') {
    console.warn(
      `initTCPlayer failed: The element type must be <video> instead of <${elTag}>`
    )
    return
  }
  const player = TCPlayer(elementId, {
    appID: tcAppID,
    fileID: vid,
    autoplay: false,
    preload: 'meta',
    loop: true,
    controls: false,
    bigPlayButton: false
  })
  return player
}
