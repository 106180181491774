import axios from 'axios'
import { gotoAuth } from '@/services/auth.js'
import { servBaseURL, storageKeys, tokenAuthURL } from '@/env.js'

const instance = axios.create({
  baseURL: servBaseURL,
  timeout: 30 * 1000
})

instance.interceptors.request.use(
  function(config) {
    config.headers.token = localStorage.getItem(storageKeys.token)
    config.headers.officialopenid = localStorage.getItem(storageKeys.official_open_id)
      ? localStorage.getItem(storageKeys.official_open_id)
      : ''
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function(response) {
    if (response.status === 200 && response.data.errCode === 1001) {
      gotoAuth(tokenAuthURL, 'source_url')
    }
    return response.data
  },
  function(error) {
    // eslint-disable-next-line no-console
    console.error('[Axios error]', error)
    return Promise.reject(error)
  }
)

export default instance
