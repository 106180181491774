<template>
  <div class="footer">
    <div class="item" :class="{ active: active === 0 }" @click="to(0)">
      <img v-if="active === 0" src="./square-active.png" />
      <img v-else src="./square-inactive.png" />
      <span>视频广场</span>
    </div>
    <div class="item" :class="{ active: active === 1 }" @click="to(1)">
      <img v-if="active === 1" src="./whovm-active.png" />
      <img v-else src="./whovm-inactive.png" />
      <span>谁查看我</span>
    </div>
    <div class="item" :class="{ active: active === 2 }" @click="to(2)">
      <img v-if="active === 2" src="./me-active.png" class="me-icon" />
      <img v-else src="./me-inactive.png" class="me-icon" />
      <span>我的</span>
    </div>
  </div>
</template>

<script>
import { PUBLIC_URL, whovmURL, storageKeys } from '@/env'
export default {
  props: ['active'],
  computed: {
    path() {
      return this.$route.path
    }
  },
  methods: {
    to(idx) {
      if (this.active === idx) return
      switch (idx) {
        case 0:
          window.location.href =
            PUBLIC_URL +
            '/#/' +
            '？=' +
            localStorage.getItem(storageKeys.official_open_id)
          break
        case 1:
          window.location.href =
            whovmURL +
            '/#/?source=video&official_open_id=' +
            localStorage.getItem(storageKeys.official_open_id)
          break
        case 2:
          window.location.href =
            whovmURL +
            '/#/personalCenter?source=video&official_open_id=' +
            localStorage.getItem(storageKeys.official_open_id)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer,
.footer * {
  box-sizing: border-box;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 110px;
  height: calc(110px + constant(safe-area-inset-bottom));
  height: calc(110px + env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 750px;
  background: #fafafa;
  display: flex;
  align-items: center;
  z-index: 500;
  & .item {
    flex-basis: 33.3%;
    width: 33.3%;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 2%;
    > span {
      color: #676769;
      font-size: 22px;
      margin-top: 8px;
    }
    &:nth-child(1) {
      > img {
        width: 13.6%;
      }
    }
    &:nth-child(2) {
      & img {
        width: 16%;
      }
    }
    &:nth-child(3) {
      & img {
        width: 12.8%;
      }
    }
    &.active {
      > span {
        color: #1cb7a8;
      }
    }
  }
}
</style>
