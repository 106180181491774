<template>
  <div class="upload-video-container">
    <div class="input-field">
      <div class="field-title" style="margin-top: 0;">视频标题</div>
      <input
        v-model="form.title"
        placeholder="请输入视频标题"
        class="field-input"
        type="text"
      />
    </div>

    <div class="input-field">
      <div class="field-title">视频介绍</div>
      <textarea
        v-model="form.summary"
        placeholder="简单介绍一下视频"
        class="field-input field-textarea"
      ></textarea>
    </div>

    <div class="input-field">
      <div class="field-title">视频标签</div>
      <div class="tag-wrap">
        <div
          v-for="(tag, index) in tags"
          :key="tag.id"
          class="tag"
          :class="{
            selected: tag.selected
          }"
          @click="toggleTagSelect(index)"
        >
          {{ tag.name }}
          <div v-if="tag.selected" class="selected-icon">
            <van-icon name="success" color="#fff" />
          </div>
        </div>
      </div>
    </div>

    <div class="input-field" style="align-items: flex-start;">
      <div class="field-title">上传视频</div>
      <label v-if="!selectedFile" class="upload" for="v-file">
        <img src="../assets/img/upload-video/photo.png" />
      </label>
      <label v-else class="upload-selected" for="v-file">
        <div>
          <span>{{ selectedFile.name }}</span>
          <van-icon
            name="close"
            color="#c00"
            @click.stop.prevent="selectedFile = ''"
          />
        </div>
      </label>
    </div>

    <input
      id="v-file"
      ref="vFileRef"
      type="file"
      accept="video/mp4"
      style="display: none;"
      @change="onFileChange"
    />

    <div class="submit-wrap">
      <button class="submit-btn" @click="submit">提交</button>
    </div>

    <div class="rule-hint">
      <van-checkbox
        v-model="ruleChecked"
        shape="square"
        icon-size="14px"
        checked-color="#00b7ae"
        class="rule-checkbox"
        ><span>声明：上传即代表您已阅读并同意</span>
        <span class="rule-link" @click.stop="showRule">《圆桌保视协议》</span>
        <span
          >。涉及侵权的内容将会被移除，严禁上传含有反动、低俗及淫秽色情内容的视频。</span
        >
      </van-checkbox>
    </div>

    <div v-if="showSuccessPopup" class="success-mask">
      <div class="success-wrap">
        <img src="../assets/img/upload-video/success.png" />
        <p>上传成功</p>
        <p>审核通过即可使用</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog, Icon, Popup, Checkbox } from 'vant'
import TcVod from 'vod-js-sdk-v6'
import { setWxShare } from '@/common/index'
import { storageKeys } from '@/env'

Vue.use(Icon)
  .use(Popup)
  .use(Checkbox)

export default {
  name: 'UploadVideo',
  data() {
    return {
      tags: [],
      form: {
        title: '',
        summary: ''
      },
      selectedFile: '',
      uploadingProgress: 0,
      showSuccessPopup: false,
      ruleChecked: true
    }
  },
  computed: {
    selectedTagIds() {
      return this.tags
        .filter(t => t.selected)
        .map(t => t.id)
        .join(',')
    }
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    this.loadTags()
    document.title = '上传视频'
    setWxShare()
  },
  methods: {
    showRule() {
      this.$root.$children[0].showRule = true
    },
    async loadTags() {
      try {
        const res = await this.$axios.get('/video/v1/video/label_list')
        if (res.errCode === 0) {
          this.tags = res.data
        } else if (res.errCode !== 1001) {
          Toast(res.errMsg)
        }
      } catch (error) {
        console.error('拉取标签失败', error)
        Toast('拉取标签失败')
      }
    },
    toggleTagSelect(index) {
      this.$set(this.tags[index], 'selected', !this.tags[index].selected)
    },
    async submit() {
      this.form.title = this.form.title.trim()
      this.form.summary = this.form.summary.trim()
      if (!this.form.title) {
        Toast('请输入视频标题')
        return
      }
      if (this.form.title.length < 5 || this.form.title.length > 30) {
        Toast('视频标题长度应在5~30个字符之间')
        return
      }
      // if (!this.selectedTagIds) {
      //   Toast('请为视频选择合适的标签')
      //   return
      // }
      if (!this.selectedFile) {
        Toast('请选择视频文件')
        return
      }

      if (!this.ruleChecked) {
        Toast('您需要阅读并同意《圆桌保视协议》才可上传视频')
        return
      }

      const data = {
        title: this.form.title,
        summary: this.form.summary,
        tag_ids: this.selectedTagIds
      }
      this.toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '视频上传中...'
      })
      try {
        const res = await this.$axios.post('/video/v1/video/create', data)
        if (res.errCode === 0) {
          this.vSignature = res.data.sign
          await this.upload()
          await this.$axios.post('/video/v1/video/upload_confirm', {
            id: res.data.video_id,
            confirm_token: res.data.confirm_token
          })

          Toast.clear()
          this.showSuccessPopup = true
          const from = this.$route.query.from
          setTimeout(() => {
            this.showSuccessPopup = false
            sessionStorage.setItem(storageKeys.myVideosNeedRefresh, 1)
            if (from === 'myvideos') {
              this.$router.go(-1)
            } else {
              this.$router.replace('/my-videos?tab=1')
            }
          }, 2000)
        } else {
          Toast(res.errMsg)
        }
      } catch (error) {
        console.error('保存视频失败', error)
        Toast('保存视频失败')
      }
    },
    initTcVod() {
      this.tcVod = new TcVod({
        getSignature: () => {
          return Promise.resolve(this.vSignature)
        }
      })
      return this.tcVod
    },
    upload() {
      return new Promise(resolve => {
        const tcVod = this.initTcVod()
        const params = {}
        const mediaFile = this.selectedFile
        if (mediaFile) params.mediaFile = mediaFile
        console.log('upload params', params)
        const uploader = tcVod.upload(params)
        // 视频上传进度
        uploader.on('media_progress', info => {
          this.uploadingProgress = info.percent
          console.log('upload progress', this.uploadingProgress)
          this.toast.message = `视频上传中...(${parseInt(info.percent * 100)}%)`
        })

        uploader.done().then(res => {
          console.log('upload ok', res)
          resolve(res)
        })
      })
    },
    onFileChange() {
      const file = this.$refs.vFileRef.files[0]
      if (!file) {
        this.selectedFile = ''
        return
      }
      const MAX_SIZE = 1024 * 1024 * 50
      if (file.size >= MAX_SIZE) {
        this.selectedFile = ''
        Dialog({ message: '文件过大，请选择小于50M的视频文件' })
        return
      }
      this.selectedFile = this.$refs.vFileRef.files[0]
    }
  }
}
</script>

<style lang="less" scoped>
.upload-video-container {
  padding: 40px;
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .input-field {
    display: flex;
    align-items: baseline;
  }
  .field-title {
    color: #121212;
    font-weight: bold;
    font-size: 36px;
    margin-top: 30px;
    width: 170px;
    flex-basis: 170px;
    flex-shrink: 0;
  }
  .field-input {
    border: none;
    background-color: #fafafa;
    border-radius: 16px;
    font-size: 32px;
    height: 90px;
    line-height: 90px;
    padding: 0 32px;
    width: 500px;
  }
  .field-textarea {
    height: 200px;
    line-height: 48px;
    width: 500px;
    padding: 20px 32px;
    resize: none;
  }
  .tag-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 516px;
    margin-left: -16px;
  }
  .tag {
    margin-top: 16px;
    display: block;
    height: 96px;
    line-height: 96px;
    padding: 0 32px;
    font-size: 32px;
    color: #1e1e1e;
    background-color: #fafafa;
    border: 2px solid #fafafa;
    border-radius: 16px;
    margin-left: 16px;
    position: relative;
    overflow: hidden;
    &.selected {
      color: #00b7ae;
      border-color: #00b7ae;
    }
    .selected-icon {
      position: absolute;
      top: -30px;
      right: -30px;
      background: #00b7ae;
      font-size: 28px;
      width: 60px;
      height: 60px;
      transform: rotate(45deg);
      > i {
        position: absolute;
        bottom: 0px;
        left: 16px;
        transform: rotate(-45deg);
      }
    }
  }
  .upload {
    width: 240px;
    height: 240px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    border-radius: 16px;
    > img {
      width: 88px;
      height: 72px;
    }
  }
  .upload-selected {
    margin-top: 20px;
    font-size: 32px;
    > div {
      display: flex;
      align-items: center;
      height: 80px;
    }
  }
  .submit-btn {
    width: 670px;
    height: 98px;
    line-height: 98px;
    background-color: #00b7ae;
    font-size: 36px;
    color: #fff;
    font-weight: bold;
    border-radius: 50px;
    border: none;
    margin: 40px auto 0;
  }
}

.success-mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  .success-wrap {
    width: 352px;
    height: 352px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    > img {
      width: 92px;
      height: 92px;
      margin-bottom: 20px;
    }
    > p {
      font-size: 32px;
      line-height: 42px;
      color: #666;
    }
  }
}

.rule-hint {
  font-size: 24px;
  width: 650px;
  margin: 12px auto 0;
  padding-bottom: 40px;
  .rule-link {
    color: #00b7ae;
    text-decoration: underline;
  }
}
</style>

<style lang="less">
.upload-video-container {
  .upload-selected .van-icon-close {
    padding: 14px;
  }

  .rule-checkbox {
    align-items: flex-start;
    .van-icon {
      position: relative;
      top: 6px;
    }
  }
}
</style>
