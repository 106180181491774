<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex'
import { tcAppID } from '@/env'

let viewTimeRecord
let pushTrackTimeLeft
let totalViewTime

export default {
  name: 'Video',
  computed: {
    ...mapState('video', [
      'globalVideoPlayer',
      'playList',
      'activeIndex',
      'videoInfoMap',
      'userWillingToPlay'
    ]),
    uid() {
      if (!this.playList) return null
      if (!this.playList[this.activeIndex]) return null
      return this.playList[this.activeIndex].uid
    },
    curVideoInfo() {
      if (!this.uid) return null
      return this.videoInfoMap[this.uid]
    }
  },
  async mounted() {
    const authOk = await this.$Auth()
    if (!authOk) return
    this.$store.commit('video/setShowVideoPage', true)
    const uid = this.$route.query.uid
    if (!uid) return
    if (this.$route.query.entrance) {
      this.$store.commit('video/setEntrance', this.$route.query.entrance)
    }

    this.startTrackViewTime()
    if (!this.playList) {
      // 分享入口
      if (!this.$videoPlayerContainer) {
        await this.$nextTick()
      }
      const errCode = await this.$videoPlayerContainer.requestVideoInfo(uid)
      // 无效视频的处理
      if (errCode === 2003) {
        this.$store.commit('video/setShowVideoPage', false)
        this.$router.replace({
          path: '/video-404',
          query: {
            type: 'invalid'
          }
        })
        return
      }
      const videoInfo = this.videoInfoMap[uid]
      if (!videoInfo) return
      this.trackSensors(videoInfo)
      document.title = videoInfo.title
      // 推荐视频的加载逻辑
      this.$store.commit('video/setActiveIndex', 0)
      this.$store.commit('video/setColumnId', '$shareRecommends')
      this.$store.commit('video/setPlayList', [
        {
          uid,
          vid: videoInfo.vid,
          image_url: videoInfo.image_url,
          title: videoInfo.title
        }
      ])
      this.$store.commit('video/setPlayPagination', {
        page: 0,
        pageSize: 10,
        finished: false,
        loading: false,
        error: false
      })
      await this.$store.dispatch('video/loadNextPage')

      this.$videoPlayerContainer.videoLoadTimeLog = {
        uid: uid,
        loadStartTime: Date.now()
      }
      this.globalVideoPlayer.loadVideoByID({
        fileID: videoInfo.vid,
        appID: tcAppID
      })
      if (this.userWillingToPlay) {
        this.globalVideoPlayer
          .$promisifyEvent('one', 'loadedmetadata')
          .then(() => {
            this.$videoPlayerContainer.vLoading = true
            this.$videoPlayerContainer.play({ trigger: 'AUTOPLAY' })
          })
      }
      await this.$nextTick()
      this.updateSwiper()
    } else {
      this.$videoPlayerContainer.requestVideoInfo(uid).then(errCode => {
        // 无效视频的处理
        if (errCode === 2003) {
          this.$store.commit('video/setShowVideoPage', false)
          this.$router.replace({
            path: '/video-404',
            query: {
              type: 'invalid'
            }
          })
          return
        }
        const videoInfo = this.videoInfoMap[uid]
        document.title = videoInfo.title
        this.trackSensors(videoInfo)
      })
    }
  },
  beforeDestroy() {
    this.stopTrackViewTime()
    this.$store.dispatch('video/play', false)
    this.$store.commit('video/setShowVideoPage', false)
    this.$store.commit('video/setPlayList', [])
    this.updateSwiper()
  },
  methods: {
    updateSwiper() {
      if (window.__videoSwiper) {
        window.__videoSwiper.update()
      } else {
        const watcher = setInterval(() => {
          if (!window.__videoSwiper) return
          window.__videoSwiper.update()
          clearInterval(watcher)
        }, 50)
      }
    },
    trackSensors(videoInfo) {
      if (!videoInfo) return
      this.$sensorsTrack('ViewAgentPage', {
        agent_id: videoInfo.uuid,
        is_author_view: !!videoInfo.card_is_self,
        page_type: '视频'
      })
    },
    startTrackViewTime() {
      viewTimeRecord = null
      pushTrackTimeLeft = 2
      totalViewTime = 0
      // 负责记录浏览时长
      this.viewTimeRecorder = setInterval(() => {
        const v = this.curVideoInfo
        if (!v) return
        if (!v.log_id) return
        // 视频更改时上报一次数据
        if (viewTimeRecord && v.log_id !== viewTimeRecord.log_id) {
          this.pushTrackViewTime()
          pushTrackTimeLeft = 2
          totalViewTime = 0
        }
        if (!viewTimeRecord) {
          viewTimeRecord = {
            log_id: v.log_id,
            unionid: v.unionid,
            view_seconds: 1
          }
        } else {
          viewTimeRecord.view_seconds++
        }
      }, 1000)
      // 负责上报数据
      setTimeout(() => {
        this.viewTimeTracker = setInterval(() => {
          if (pushTrackTimeLeft === 0) {
            this.pushTrackViewTime()
            if (totalViewTime < 30) {
              // 3 ~ 6
              pushTrackTimeLeft = Math.floor(Math.random() * 4) + 3
            } else if (totalViewTime < 60) {
              // 5 ~ 10
              pushTrackTimeLeft = Math.floor(Math.random() * 6) + 5
            } else {
              // 20
              pushTrackTimeLeft = 20
            }
          } else {
            pushTrackTimeLeft--
          }
        }, 1000)
      }, 500)
    },
    pushTrackViewTime() {
      if (viewTimeRecord) {
        totalViewTime += viewTimeRecord.view_seconds
        this.$axios.post('/video/v1/share/log_read_time', viewTimeRecord)
        viewTimeRecord = null
      }
    },
    stopTrackViewTime() {
      if (this.viewTimeRecorder) {
        clearInterval(this.viewTimeRecorder)
        this.viewTimeRecorder = null
      }
      if (this.viewTimeTracker) {
        this.pushTrackViewTime()
        clearInterval(this.viewTimeTracker)
        this.viewTimeTracker = null
      }
    }
  }
}
</script>
