<template>
  <div class="ad-manager-container">
    <ad-manager
      :ad-list="customAdList"
      :distribution-ad-list="distributionAdList"
      :async-get-promotion-fee-detail="loadPromotionFeeDetailFn"
      @addCustomAd="toAddCustomAd"
      @addPromotionAd="toAddPromotionAd"
      @editCustomAd="toEditCustomAd"
      @toDetail="toAdDetail"
      @delete="toDeleteAd"
      @close="closeAdManage"
    />
    <van-popup v-model="showNeedAuthPopup" class="need-auth-popup-wrap">
      <img src="./hint.png" />
      <h3>您还未认证</h3>
      <p>根据银保监会规定，销售保险必须<br />进行从业身份认证。</p>
      <p style="color: rgb(240, 74, 73)">未认证会导致无法获得佣金</p>

      <div class="need-auth_btns">
        <a @click="showNeedAuthPopup = false">继续添加广告</a>
        <a @click="doAuth">去认证</a>
      </div>
    </van-popup>

    <!-- 填写手机弹框 -->
    <van-popup v-model="showAuthPopup" class="phoneModal">
      <div class="content">
        <div class="title">验证手机号</div>
        <input
          v-model="phone"
          class="phone_numer"
          placeholder="请输入手机号"
          type="Number"
        />
        <div class="code_wrap">
          <input
            v-model="code"
            type="text"
            placeholder="请输入验证码"
            class="code"
          />
          <span v-show="count <= 0" @click="getCode">获取验证码</span>
          <span v-show="count > 0">{{ count }}s</span>
        </div>
        <div class="tips">
          <span>银保监会规定，需认证后才能进行分销</span>
          <span>客服电话：13302448619</span>
        </div>
        <div class="submit_btn" @click="submitPhone">提交</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import axiosInstance from '@/services/axios'
import {
  api_send_verify_code,
  api_verify_code_bind
} from '@/services/oldService'

export default {
  data() {
    return {
      userInfo: null,
      customAdList: [],
      distributionAdList: [],
      async loadPromotionFeeDetailFn(ad) {
        try {
          const data = await axiosInstance.get(
            '/video/v1/custom_adv/get_one_custom_adv',
            {
              params: { advert_id: ad.id }
            }
          )
          if (data.errCode === 0) {
            return data.data.datas.promotion_fee_detail || '暂无数据'
          }
        } catch (error) {}
      },
      showNeedAuthPopup: false,
      showAuthPopup: false,
      sso_id: '',
      token: '',
      phone: '',
      code: '',
      count: 0
    }
  },
  computed: {
    ownAdIds() {
      return this.customAdList.map(t => t.id)
    }
  },
  mounted() {
    this.loadCustomAdList()
    this.loadCustomAdList({ type: 1 })
  },
  methods: {
    async toAdDetail(ad) {
      try {
        const data = await axiosInstance.post('/video/v1/custom_adv/authXrk', {
          adv_id: ad.id
        })
        if (data.errCode === 0) {
          setTimeout(() => {
            if(data.data.sso_id){
              window.location.href = ad.link + '&sso_id=' + data.data.sso_id
            }else {
              window.location.href = ad.link
            }
          }, 200)
        } else if (data.errCode === 1409) {
          this.showAuthPopup = true
        } else {
          this.$toast(data.errMsg)
        }
      } catch (error) {}
    },
    doAuth() {
      window.location.href =
        'https://super.bxr.im/mall_products/inquiry/hang_up_idcard_page' +
        '?sso_id=' +
        this.sso_id +
        '&token=' +
        this.token
    },
    getCode() {
      if (!this.phone) {
        this.$toast('请输入手机号码')
        return false
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.$toast('请输入正确手机号码')
        return false
      }
      this.$toast('已发送验证码')
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.count = 60
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--
        }
        if (this.count <= 0) {
          this.count = 0
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
      api_send_verify_code({
        mobile: this.phone
      }).catch(e => {
        clearInterval(this.timer)
        this.timer = null
        this.count = 0
      })
    },
    submitPhone() {
      api_verify_code_bind({
        mobile: this.phone,
        code: this.code
      })
        .then(res => {
          if (res.code === 200) {
            this.$toast('验证成功')
            this.showAuthPopup = false
          } else {
            this.$toast(res.error)
          }
        })
        .catch(e => {
          this.$toast('验证失败')
        })
    },
    loadCustomAdList({ success, type } = {}) {
      const listKey = type === 1 ? 'distributionAdList' : 'customAdList'
      const loadPage = async page => {
        try {
          const data = await axiosInstance.get(
            '/video/v1/custom_adv/get_list',
            {
              params: {
                page,
                page_num: this.adListPagination || 20,
                type: typeof type === undefined ? '' : type
              }
            }
          )
          const datas = data.data.list.datas
          if (data.errCode === 0) {
            if (page === 1) {
              this[listKey] = []
            }
            this[listKey].push(...datas.data)
            if (datas.last_page > datas.current_page) {
              loadPage(page + 1)
            } else {
              const total = datas.total
              if (total > 20) {
                this.adListPagination = total + 5
              }
              if (success) success()
            }
          }
        } catch (error) {
          console.error('获取自定义广告列表失败', error)
        }
      }
      loadPage(1)
    },
    toAddCustomAd() {
      this.$router.push('/custom-ad-edit')
    },
    toEditCustomAd(ad) {
      this.$router.push({
        path: '/custom-ad-edit',
        query: {
          id: ad.id
        }
      })
    },
    toDeleteAd(ad) {
      const idx = this.customAdList.findIndex(t => t === ad)
      Dialog.confirm({
        title: '提示',
        message: '确认要删除该广告吗？'
      })
        .then(() => {
          axiosInstance
            .post('/video/v1/custom_adv/del_one', {
              status: 1,
              adv_id: ad.id
            })
            .then(data => {
              if (data.errCode === 0) {
                this.customAdList.splice(idx, 1)
                this.$toast('删除成功')
              }
            })
        })
        .catch(() => {})
    },
    async toAddPromotionAd(ad) {
      try {
        const data = await axiosInstance.post(
          '/video/v1/custom_adv/add_promotion_adv',
          {
            adv_id: ad.id
          }
        )
        if (data.errCode === 0) {
          this.customAdList = []
          this.loadCustomAdList()
          if (data.data.lawyer_license_status === 0) {
            this.sso_id = data.data.sso_id
            this.token = data.data.token
            this.showNeedAuthPopup = true
          } else {
            this.$toast('添加成功')
          }
        } else if (data.errCode === 1409) {
          this.showAuthPopup = true
        } else {
          this.$toast(data.errMsg)
        }
      } catch (error) {}
    },
    closeAdManage() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.ad-manager-container {
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
}
.need-auth-popup-wrap {
  width: 616px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  > img {
    width: 134px;
  }
  > h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 20px 0;
  }
  > p {
    font-size: 28px;
    text-align: center;
  }
  .need-auth_btns {
    display: flex;
    justify-content: space-between;
    width: 500px;
    margin-top: 20px;
    > a {
      width: 230px;
      height: 80px;
      background: #00b7ae;
      border-radius: 44px;
      font-size: 32px;
      line-height: 80px;
      border: 1px solid #00b7ae;
      outline: none;
      color: #fff;
      text-align: center;
      &:nth-child(1) {
        background: none;
        color: #00b7ae;
      }
    }
  }
}

.phoneModal {
  border-radius: 16px;
}
.van-popup {
  .content {
    width: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
    .title {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 36px;
      color: #000;
      font-weight: 500;
    }
    .phone_numer {
      width: 492px;
      height: 80px;
      background-color: #f4f5f6;
      border-radius: 4px;
      margin-top: 32px;
      padding-left: 30px;
    }
    .code_wrap {
      width: 492px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f4f5f6;
      border-radius: 4px;
      margin-top: 24px;
      padding-right: 10px;
      .code {
        width: 300px;
        background-color: #f4f5f6;
        height: 80px;
        padding-left: 30px;
        border-radius: 4px;
      }
      span {
        width: 174px;
        height: 62px;
        background-color: #edaf33;
        border-radius: 12px;
        font-size: 24px;
        color: #fff;
        text-align: center;
        line-height: 62px;
      }
    }
    .tips {
      padding: 0 50px;
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      span {
        line-height: 34px;
        font-size: 24px;
        color: #9b9b9b;
      }
    }
    .submit_btn {
      width: 500px;
      height: 80px;
      background: #00b7ae;
      border-radius: 44px;
      font-size: 32px;
      line-height: 80px;
      border: 1px solid #00b7ae;
      outline: none;
      color: #fff;
      text-align: center;
      margin-top: 20px;
    }
    &::placeholder {
      color: #9b9b9b;
      font-size: 28px;
    }
  }
}
</style>
