<template>
  <div class="v-container">
    <div :style="scrollerStyle" class="v-img-scroller">
      <div v-for="(v, index) in list" :key="index" class="swiper-slide">
        <div v-if="v.image_url" class="slide-inner-wrap">
          <van-image class="v-cover-img" fit="contain" :src="v.image_url">
          </van-image>
        </div>
      </div>
    </div>
    <div :style="playerStyle" class="v-player-scroller">
      <div class="water-print">用户上传 版权归原作者</div>
      <div class="swiper-slide">
        <video
          id="global-player"
          class="v-player"
          loop
          playsinline
          webkit-playsinline
          x5-video-player-type="h5-page"
        />
      </div>
    </div>
    <div id="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(v, index) in list" :key="index" class="swiper-slide">
          <div class="slide-inner-wrap" @click="togglePlay">
            <div
              v-if="nextActiveIndex !== index || (!isPlaying && !vLoading)"
              :class="{ pauseTapAnimation }"
              class="v-paused"
            >
              <img :src="pausedIcon" />
            </div>
            <van-loading
              v-if="vLoading && nextActiveIndex === index"
              class="v-loading"
              size="50px"
            />
            <div v-if="videoInfoMap[v.uid]" class="v-card">
              <div class="v-sider">
<!--                <div-->
<!--                  class="sider-item"-->
<!--                  @click.stop="onClickButton($event, 'freeSuggestion')"-->
<!--                >-->
<!--                  <img class="icon" :src="consultationIcon" />-->
<!--                  <img-->
<!--                    class="icon-text"-->
<!--                    :src="staticURL + '/mianfeizixun-text.png'"-->
<!--                  />-->
<!--                </div>-->
                <div
                  v-show="
                    videoInfoMap[v.uid].card_is_self ||
                      videoInfoMap[v.uid].wx_qrcode_url
                  "
                  class="sider-item"
                  @click.stop="onClickButton($event, 'showWxQrcode')"
                >
                  <img class="icon" :src="weixinIcon" />
                  <img
                    class="icon-text"
                    :src="staticURL + '/jiawoweixin-text.png'"
                  />
                </div>
                <div
                  v-show="videoInfoMap[v.uid].card_is_self"
                  class="sider-item"
                  @click.stop="onClickButton($event, 'showShareText')"
                >
                  <img class="icon" :src="textIcon" />
                  <img
                    class="icon-text"
                    :src="staticURL + '/shipinpeiwen-text.png'"
                  />
                </div>
              </div>
              <!-- @click.stop 阻止关闭广告编辑弹窗时触发点击播放按钮 -->
              <div
                v-if="nextActiveIndex === index"
                class="custom-ad"
                @click.stop="() => {}"
              >
                <VideoAd
                  :is-author="!!videoInfoMap[v.uid].card_is_self"
                  :resource-id="v.vid"
                />
              </div>
              <div class="head">
                <div
                  class="portrait"
                  @click.stop="onClickButton($event, 'agentCard')"
                >
                  <img :src="videoInfoMap[v.uid].avatar" />
                </div>
                <div
                  class="info"
                  @click.stop="onClickButton($event, 'agentCard')"
                >
                  <p class="name">{{ videoInfoMap[v.uid].name }}</p>
                  <p class="phone">{{ videoInfoMap[v.uid].phone }}</p>
                </div>
                <div v-show="!videoInfoMap[v.uid].card_is_self" class="btn">
                  <img
                    v-if="inActivityTime"
                    src="./discount-tag.png"
                    class="discount-tag"
                  />
                  <button @click.stop="onClickButton($event, 'switchMyCard')">
                    换成我的名片
                  </button>
                </div>
              </div>
              <div class="vText">
                {{ videoInfoMap[v.uid].video_desc }}
              </div>

              <a
                class="v-complaint"
                @click.stop="onClickButton($event, 'complaint')"
              >
                <img src="../assets/img/complaint.png" />
              </a>
            </div>
            <div class="v-progress" :style="progressStyle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Loading, Image } from 'vant'
import { inActivityTime } from '@/common/index'
import VideoAd from '@/components/VideoAd'

Vue.use(Loading).use(Image)

export default {
  name: 'VideoPlayerUI',
  components: { VideoAd },
  props: [
    'pausedIcon',
    'consultationIcon',
    'weixinIcon',
    'textIcon',
    'pauseTapAnimation',
    'list',
    'vLoading',
    'isPlaying',
    'activeIndex',
    'nextActiveIndex',
    'swiper',
    'videoInfoMap',
    'playProgress'
  ],
  data() {
    return {
      inActivityTime: false,
      swiperHeight: 0,
      scrollerTranslate: 0,
      playerZIndex: 89,
      transitionDuration: 0,
      staticURL: 'https://oss-img.topmdrt.com/video-client'
    }
  },
  computed: {
    scrollerStyle() {
      const transformStyle =
        `transform: translate3d(0px, ${this.scrollerTranslate}px, 0px);` +
        `transition-duration: ${this.transitionDuration}ms;`
      return transformStyle
    },
    playerStyle() {
      const positionY =
        this.scrollerTranslate + this.swiperHeight * this.activeIndex
      const transformStyle =
        `transform: translate3d(0px, ${positionY}px, 0px);` +
        `transition-duration: ${this.transitionDuration}ms;`
      const zIndexStyle = `z-index: ${this.playerZIndex};`
      return transformStyle + zIndexStyle
    },
    progressStyle() {
      return { width: `${this.playProgress * 100}%` }
    }
  },
  mounted() {
    this.inActivityTime = inActivityTime(Date.now())
    if (this.swiper) {
      this.initSwiperEvents()
    } else {
      const unWatch = this.$watch('swiper', swiper => {
        if (swiper) {
          this.initSwiper()
        }
        unWatch()
      })
    }
  },
  methods: {
    initSwiper() {
      const vm = this
      this.swiperHeight = this.swiper.height
      this.swiper.on('setTranslate', e => {
        vm.scrollerTranslate = e
      })
      this.swiper.on('setTransition', e => {
        vm.transitionDuration = e
      })
      this.swiper.on('resize', () => {
        setTimeout(() => {
          vm.swiperHeight = vm.swiper.height
        }, 100)
      })
    },
    hidePlayer() {
      this.playerZIndex = 89
    },
    showPlayer() {
      this.playerZIndex = 99
    },
    togglePlay() {
      this.$emit('togglePlay')
    },
    onClickButton(event, type) {
      this.$emit('clickButton', event, type)
    }
  }
}
</script>

<style lang="less" scoped>
.v-container {
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow-x: hidden;
  position: relative;
}
#swiper-container,
.v-img-scroller,
.v-player-scroller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}
.v-img-scroller {
  .slide-inner-wrap {
    background: #000;
  }
}
.v-player-scroller {
  z-index: 99;
}
.water-print {
  font-size: 24px;
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgba(255, 255, 255, 0.15);
  z-index: 1;
}
#swiper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.swiper-slide {
  height: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .slide-inner-wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.v-player,
.v-cover-img {
  width: 100%;
  height: 100%;
}
.v-card {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 200;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.01) 0%,
    rgba(0, 0, 0, 0.63) 100%
  );
  padding: 100px 30px 60px;
  .custom-ad {
    margin-bottom: 40px;
    margin-left: -30px;
    position: relative;
    height: 64px;
  }
  .head {
    display: flex;
    align-items: center;
    .portrait {
      width: 90px;
      height: 90px;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      margin-left: 22px;
      > p {
        color: #fff;
        margin: 0;
      }
      .name {
        font-size: 28px;
        line-height: 40px;
      }
      .phone {
        font-size: 24px;
        line-height: 34px;
      }
    }
    .btn {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      position: relative;
      > button {
        padding: 0 34px;
        height: 72px;
        line-height: 72px;
        background: #00b7ae;
        color: #fff;
        font-size: 28px;
        border: none;
        border-radius: 50px;
      }
      .discount-tag {
        width: 95px;
        position: absolute;
        top: -48px;
        right: 110px;
      }
    }
  }
  .vText {
    margin-top: 30px;
    font-size: 30px;
    line-height: 40px;
    color: #fff;
  }
}
.v-sider {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 24px;
  transform: translateY(-100%);
  .sider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img.icon {
      width: 88px;
      height: 88px;
    }
    > img.icon-text {
      width: 108px;
      margin-top: 8px;
    }
    > span {
      font-size: 24px;
      line-height: 34px;
      color: #fff;
      margin-top: 6px;
    }
    & + .sider-item {
      margin-top: 20px;
    }
  }
}
.v-complaint {
  position: absolute;
  z-index: 201;
  right: 20px;
  bottom: 10px;
  padding: 10px;
  > img {
    width: 50px;
    height: 50px;
  }
}
.v-progress {
  height: 2px;
  position: absolute;
  z-index: 201;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@keyframes scaleInto {
  from {
    transform: scale(2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.v-paused {
  width: 120px;
  height: 120px;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  transform: scale(1);
  opacity: 1;
  > img {
    width: 100%;
    height: 100%;
  }
  &.pauseTapAnimation {
    animation: scaleInto 0.1s linear;
  }
}
.v-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
}
</style>
