<template>
  <div class="s-guide">
    <!-- step0 -->
    <div v-if="step === 0" class="guide-0-0 guide-bg"></div>
    <div v-if="step === 0" class="guide-0-blank-0"></div>
    <div v-if="step === 0" class="guide-bg guide-0-1">
      <div class="guide-0-arrow-wrap">
        <img class="guide-0-arrow" src="../assets/img/square-guide/arrow.png" />
        <img class="guide-0-arrow guide-0-arrow-right" src="../assets/img/square-guide/arrow.png" />
      </div>
      <p>滑动可以查看更多视频分类</p>
      <a class="guide-btn" @click="nextGuide">我知道了</a>
    </div>
    <!-- step1 -->
    <div v-if="step === 1" class="guide-1-0 guide-bg"></div>
    <div v-if="step === 1" class="guide-1-blank-0"></div>
    <div v-if="step === 1" class="guide-bg guide-1-1">
      <p>点击搜索视频，尝试输入“社保”看看</p>
      <a class="guide-btn" @click="nextGuide">我知道了</a>
    </div>
    <!-- step2 -->
    <div v-if="step === 2" class="guide-2-0 guide-bg">
      <a class="guide-btn" @click="nextGuide">我知道了</a>
      <p>这里可以看到谁浏览过你的视频</p>
    </div>
    <div v-if="step === 2" class="guide-2-1">
      <div class="guide-bg" style="flex-basis: 35%;"></div>
      <div style="flex-basis: 30%;"></div>
      <div class="guide-bg" style="flex-basis:35%;"></div>
    </div>
    <!-- step3 -->
    <div v-if="step === 3" class="guide-3-0 guide-bg">
      <a class="guide-btn" @click="nextGuide">我知道了</a>
      <p>这里是你的个人中心</p>
    </div>
    <div v-if="step === 3" class="guide-3-1">
      <div class="guide-bg" style="flex-basis: 68%;"></div>
      <div style="flex-basis: 30%;"></div>
      <div class="guide-bg" style="flex-basis:2%;"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 0
    }
  },
  methods: {
    nextGuide() {
      const nextStep = this.step + 1
      this.step = nextStep
      if (nextStep > 3) {
        this.$emit('finish')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.s-guide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 600;
}
.guide-bg {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 28px;
}
.guide-btn {
  width: 260px;
  height: 80px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  border-radius: 12px;
}
.guide-0-0 {
  flex-basis: 10px;
  flex-shrink: 0;
}
.guide-0-blank-0 {
  flex-basis: 90px;
  flex-shrink: 0;
}
.guide-0-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .guide-0-arrow-wrap {
    display: flex;
  }
  .guide-0-arrow {
    width: 84px;
    height: 32px;
  }
  .guide-0-arrow-right {
    transform: scaleX(-1);
    margin-left: 30px;
  }
  > p {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.guide-1-0 {
  flex-basis: 108px;
}
.guide-1-blank-0 {
  flex-basis: 90px;
}
.guide-1-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  > p {
    margin-bottom: 50px;
  }
}
.guide-2-0 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  >p {
    margin-top: 30px;
  }
}
.guide-2-1 {
  flex-basis: 110px;
  flex-basis: calc(110px + constant(safe-area-inset-bottom));
  flex-basis: calc(110px + env(safe-area-inset-bottom));
  display: flex;
}
.guide-3-0 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 30px;
  padding-right: 30px;
  >p {
    margin-top: 30px;
  }
}
.guide-3-1 {
  flex-basis: 110px;
  flex-basis: calc(110px + constant(safe-area-inset-bottom));
  flex-basis: calc(110px + env(safe-area-inset-bottom));
  display: flex;
}
</style>
