<template>
  <div class="video-404-container">
    <img
      v-if="type === 'lost'"
      class="not-found-img"
      src="../assets/img/not-found.png"
    />
    <p v-if="type === 'lost'" class="not-found-text">
      您来晚了，该视频已下架！
    </p>
    <img
      v-if="type === 'invalid'"
      class="invalid-img"
      src="../assets/img/invalid.png"
    />
    <p v-if="type === 'invalid'" class="not-found-text invalid-text">
      该内容暂时无法显示
    </p>
    <p v-if="type === 'invalid'" class="not-found-text">
      如有疑问请与客服联系
    </p>
  </div>
</template>

<script>
export default {
  name: 'Video404',
  data() {
    return {
      type: 'lost'
    }
  },
  async mounted() {
    const type = this.$route.query.type
    if (type) this.type = type
    document.title = '视频已失效'
  }
}
</script>

<style lang="less" scoped>
.video-404-container {
  height: 100vh;
  background: #f6f6f6;
  .not-found-img {
    width: 464px;
    height: 366px;
    margin-top: 300px;
    margin-left: 132px;
  }
  .invalid-img {
    width: 452px;
    height: 538px;
    margin-top: 300px;
    margin-left: 148px;
  }
  .not-found-text {
    color: #29292f;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }
  .invalid-text {
    margin-top: -280px;
  }
}
</style>
